.notifications {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.notifications .overlap-group-wrapper {
  background-color: #ffffff;
  width: 375px;
  height: fit-content;
}

.notifications .overlap-group {
  position: relative;
  width: 320px;
  height: 51px;
  top: 43px;
  left: 23px;
}

.notifications .frame {
  display: inline-flex;
  flex-direction: column;
  height: 28px;
  align-items: center;
  gap: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.notifications .div {
  display: flex;
  width: 320px;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.notifications .text-wrapper {
  position: relative;
  width: 292px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}

.notifications .navigation-close {
  background: none;
  position: relative;
  width: 19.82px;
  height: 20px;
}

.notifications .notification-list {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -742px;
}

.notifications .ladda-dr-du-br {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  gap: 20px;
  position: relative;
}

.notifications .all-content {
  display: inline-flex;
  height: fit-content;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  position: relative;
  margin-bottom: -4px;
}

.notifications .menu-mobile-listitem {
  display: inline-flex;
  flex-direction: column;
  height: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.notifications .menu-mobile-listitem-wrapper {
  display: inline-flex;
  flex-direction: column;
  height: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
}

.notifications .frame-wrapper {
  display: flex;
  width: 318px;
  height: fit-content;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  padding: 0px 8px 0px 0px;
  position: relative;
}

.notifications .frame-2 {
  display: flex;
  width: 309px;
  height: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

.notifications .ellipse-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 0px;
  position: relative;
  flex: 0 0 auto;
}

.notifications .ellipse-active {
  position: relative;
  width: 12px;
  height: 12px;
  background-color: #2071b5;
  border-radius: 6px;
}

.notifications .ellipse {
  position: relative;
  width: 12px;
  height: 12px;
  background-color: none;
  border-radius: 6px;
}

.notifications .frame-3 {
  display: flex;
  flex-direction: column;
  width: 274px;
  align-items: stretch;
  justify-content: flex-start;
  gap: 4px;
  position: relative;
  margin-bottom: -4px;
}

.notifications .text-wrapper-2 {
  position: relative;
  width: 272.02px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.notifications .p {
  position: relative;
  width: 279.11px;
  margin-right: -5.11px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-dark-grey);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.notifications a:link {
  color: blue;
  text-decoration: none;
}

.notifications a:visited {
  color: var(--teritiary-coloursmedium-dark-grey);
  text-decoration: none;
}

.notifications .text-wrapper-3 {
  position: relative;
  width: 320px;
  margin-right: -46px;
  font-family: var(--XS-regular-font-family);
  font-weight: var(--XS-regular-font-weight);
  color: var(--texttertiary);
  font-size: var(--XS-regular-font-size);
  letter-spacing: var(--XS-regular-letter-spacing);
  line-height: var(--XS-regular-line-height);
  font-style: var(--XS-regular-font-style);
}

.notifications .action-trash {
  background: none;
  position: relative;
  width: 19.82px;
  height: 20px;
  margin-right: -12.82px;
}

.notifications .icon {
  position: absolute;
  width: 15px;
  height: 18px;
  top: 2px;
  left: 2px;
}

.notifications .divider {
  position: relative;
  width: 320px;
  height: 1px;
  margin-bottom: -13px;
}

.notifications .line {
  position: absolute;
  width: 320px;
  height: 1px;
  top: 0;
  left: 0;
}

.notifications .ellipse-2 {
  position: relative;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border-radius: 6px;
}

.notifications .text-wrapper-4 {
  position: relative;
  width: 272.02px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-coloursmagnetic-grey);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.notifications .text-wrapper-5 {
  position: absolute;
  width: 279px;
  height: 24px;
  top: 27px;
  left: 0;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-dark-grey);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.notifications .text-wrapper-6 {
  position: relative;
  width: 279px;
  height: 24px;
  top: 350px;
  left: 0;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
}

.notifications .text-wrapper-7 {
  position: relative;
  width: 272px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: #4e4848;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
