.my-ACCOUNT {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.my-ACCOUNT .div {
  background-color: #ffffff;
  overflow: hidden;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 1054px;
  position: relative;
}

.my-ACCOUNT .overlap {
  position: absolute;
  width: 376px;
  height: 976px;
  top: 0;
  left: -1px;
}

.my-ACCOUNT .frame {
  position: absolute;
  width: 333px;
  height: 257px;
  top: 162px;
  left: 22px;
  border-radius: 10px;
  overflow: hidden;
  // border: 1px solid;
  // border-color: var(--teritiary-colourslighter-grey);
}

.my-ACCOUNT .overlap-group {
  position: absolute;
  width: 333px;
  height: 53px;
  top: 10px;
  left: 0;
}

.my-ACCOUNT .frame-2 {
  display: flex;
  width: 333px;
  align-items: center;
  gap: 8.46px;
  padding: 0px 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.my-ACCOUNT .wallet-savings {
  position: relative;
  width: 21.14px;
  height: 21.14px;
}

.my-ACCOUNT .title {
  position: relative;
  width: 258.78px;
  margin-top: -1.06px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 16.9px;
  letter-spacing: 0;
  line-height: 29.6px;
}

.my-ACCOUNT .icons-pc {
  position: relative;
  width: 16.91px;
  height: 16.91px;
  margin-right: -12.75px;
}

.my-ACCOUNT .text-wrapper {
  position: absolute;
  width: 217px;
  top: 25px;
  left: 46px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 28px;
}

.my-ACCOUNT .line {
  position: absolute;
  width: 1px;
  height: 157px;
  top: 87px;
  left: 166px;
  object-fit: cover;
}

.my-ACCOUNT .frame-wrapper {
  position: absolute;
  width: 333px;
  height: 224px;
  top: 453px;
  left: 22px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--teritiary-colourslighter-grey);
}

.my-ACCOUNT .frame-3 {
  display: flex;
  width: 230px;
  align-items: center;
  gap: 8px;
  position: relative;
  top: 11px;
  left: 18px;
}

.my-ACCOUNT .cockpit {
  position: relative;
  width: 21px;
  height: 20px;
}

.my-ACCOUNT .title-2 {
  width: 178.64px;
  font-size: 16px;
  position: relative;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  letter-spacing: 0;
  line-height: 28px;
}

.my-ACCOUNT .frame-4 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  position: absolute;
  top: 249px;
  left: 45px;
}

.my-ACCOUNT .text-wrapper-2 {
  position: relative;
  width: 86.96px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
}

.my-ACCOUNT .numbers {
  position: relative;
  width: 116.87px;
  height: 116.87px;
}

.my-ACCOUNT .overlap-group-2 {
  position: relative;
  width: 117px;
  height: 117px;
}

.my-ACCOUNT .ellipse {
  width: 108px;
  height: 58px;
  left: 9px;
  position: absolute;
  top: 0;
}

.my-ACCOUNT .img {
  width: 117px;
  height: 117px;
  left: 0;
  position: absolute;
  top: 0;
}

.my-ACCOUNT .element {
  position: absolute;
  top: 39px;
  left: 30px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 400;
  color: var(--primaryblack);
  font-size: 17.1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.5px;
}

.my-ACCOUNT .span {
  font-weight: 700;
}

.my-ACCOUNT .text-wrapper-3 {
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-size: 15.3px;
}

.my-ACCOUNT .frame-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  position: absolute;
  top: 249px;
  left: 213px;
}

.my-ACCOUNT .text-wrapper-4 {
  position: relative;
  width: 66.84px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
}

.my-ACCOUNT .p {
  position: absolute;
  top: 39px;
  left: 28px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 400;
  color: var(--primaryblack);
  font-size: 17.1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.5px;
}

.my-ACCOUNT .all-content {
  position: absolute;
  width: 375px;
  height: 903px;
  top: 73px;
  left: 0;
  background-color: #ffffff;
}

.my-ACCOUNT .subitems {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 838px;
  align-items: center;
  gap: 24px;
  padding: 0px 4px;
  position: relative;
  top: 57px;
  left: 28px;
}

.my-ACCOUNT .div-2 {
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
  margin-left: -4px;
  margin-right: -4px;
}

.my-ACCOUNT .elpriser-heading {
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.my-ACCOUNT .text-wrapper-5 {
  position: relative;
  width: 292px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--textprimary);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.my-ACCOUNT .div-3 {
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.my-ACCOUNT .frame-6 {
  display: flex;
  width: 320px;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.my-ACCOUNT .text-wrapper-6 {
  position: relative;
  width: 224.92px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.my-ACCOUNT .select-option {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}

.my-ACCOUNT .text-wrapper-7 {
  position: relative;
  width: 225.61px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.my-ACCOUNT .divider {
  position: relative;
  width: 320px;
  height: 1px;
  margin-left: -4px;
  margin-right: -4px;
}

.my-ACCOUNT .line-2 {
  position: absolute;
  width: 320px;
  height: 1px;
  top: 0;
  left: 0;
}

.my-ACCOUNT .text-wrapper-8 {
  position: relative;
  width: 292px;
  margin-top: -1px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 700;
  color: var(--textprimary);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.my-ACCOUNT .text-wrapper-9 {
  position: relative;
  width: 300.32px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.my-ACCOUNT .dropdown {
  justify-content: space-between;
  display: flex;
  width: 320px;
  height: 45px;
  align-items: center;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
}

.my-ACCOUNT .select-option-2 {
  position: relative;
  // width: fit-content;
  width: 315px;
  height: 40px;
  // margin-top: -3.5px;
  // margin-bottom: -1.5px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}

.my-ACCOUNT .icons-symbol-arrow {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -0.5px;
  margin-bottom: -0.5px;
}

.my-ACCOUNT .dropdown-2 {
  gap: 24px;
  display: flex;
  width: 320px;
  height: 45px;
  align-items: center;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}

.my-ACCOUNT .dropdown-3 {
  display: flex;
  width: 320px;
  height: 45px;
  align-items: center;
  gap: 106px;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
}

.my-ACCOUNT .elprisomrade {
  display: inline-flex;
  flex-direction: column;
  height: 90px;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  margin-left: -4px;
  margin-right: -4px;
}

.my-ACCOUNT .line-wrapper {
  position: relative;
  width: 320px;
  height: 1px;
}

.my-ACCOUNT .elpriser-heading-wrapper {
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.my-ACCOUNT .link {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.my-ACCOUNT .button {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.my-ACCOUNT .img-2 {
  position: relative;
  width: 20px;
  height: 20px;
}

.my-ACCOUNT .label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--textprimary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}

.my-ACCOUNT .elprisomrade-2 {
  height: 93px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  margin-left: -4px;
  margin-right: -4px;
}

.my-ACCOUNT .elprisomrade-3 {
  height: 51px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  margin-left: -4px;
  margin-right: -4px;
}

.my-ACCOUNT .text-link {
  position: relative;
  width: 265px;
  height: 28px;
  background-color: #ffffff;
  align-self: flex-start;
}

.my-ACCOUNT .text-label {
  position: relative;
  width: 265px;
  top: -1px;
  left: 0;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--asset-colours-in-useasset-dark-blue);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
}

.my-ACCOUNT .molecules-navigation {
  position: absolute;
  width: 375px;
  height: 135px;
  top: 0;
  left: 1px;
  background-color: var(--primarywhite);
}

.my-ACCOUNT .div-wrapper {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 53px;
  position: relative;
  top: 72px;
  left: 21px;
}

.my-ACCOUNT .title-wrapper {
  display: flex;
  flex-direction: column;
  width: 208px;
  align-items: flex-start;
  position: relative;
}

.my-ACCOUNT .title-3 {
  width: 208px;
  font-size: 20px;
  position: relative;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  letter-spacing: 0;
  line-height: 28px;
}

.my-ACCOUNT .frame-7 {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 15px;
  padding: 4px 0px 16px;
  position: absolute;
  top: 990px;
  left: 21px;
  background-color: #ffffff;
}

.my-ACCOUNT .frame-8 {
  display: flex;
  flex-direction: column;
  width: 54.6px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: relative;
}

.my-ACCOUNT .icon {
  position: absolute;
  width: 20px;
  height: 13px;
  top: 4px;
  left: 0;
}

.my-ACCOUNT .text-wrapper-10 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.my-ACCOUNT .frame-9 {
  display: flex;
  flex-direction: column;
  width: 54.6px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}

.my-ACCOUNT .text-wrapper-11 {
  position: relative;
  width: fit-content;
  margin-left: -2.7px;
  margin-right: -2.7px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.my-ACCOUNT .cost-spotpris {
  position: relative;
  width: fit-content;
  margin-left: -19.2px;
  margin-right: -19.2px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-colourscoal-black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.my-ACCOUNT .text-wrapper-12 {
  position: relative;
  width: fit-content;
  margin-left: -6.7px;
  margin-right: -6.7px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.my-ACCOUNT .icon-2 {
  position: absolute;
  width: 20px;
  height: 17px;
  top: 2px;
  left: 0;
}

// .my-ACCOUNT .buttons {
//   all: unset;
//   box-sizing: border-box;
//   display: flex;
//   width: 333px;
//   align-items: center;
//   justify-content: center;
//   gap: 4px;
//   padding: 12px 36px;
//   position: relative;
//   flex: 0 0 auto;
//   background-color: var(--primary-colourssolar-yellow);
//   border-radius: 40px;
// }

// .my-ACCOUNT .button-label {
//   all: unset;
//   box-sizing: border-box;
//   position: relative;
//   width: fit-content;
//   margin-top: -1px;
//   font-family: "Vattenfall Hall-Medium", Helvetica;
//   font-weight: 500;
//   color: var(--primary-colourscoal-black);
//   font-size: 14px;
//   text-align: center;
//   letter-spacing: 0;
//   line-height: 16px;
//   white-space: nowrap;
// }

// .MyAccount {
//   background-color: #ffffff;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   width: 100%;
// }

// .MyAccount .div {
//   background-color: #ffffff;
//   // border: 1px solid;
//   // border-color: #000000;
//   width: 375px;
//   height: 667px;
//   position: relative;
// }

// .MyAccount .stacked-logos {
//   position: absolute;
//   width: 143px;
//   height: 103px;
//   top: 170px;
//   left: 116px;
// }

// .MyAccount .boolean-group {
//   position: absolute;
//   width: 143px;
//   height: 16px;
//   top: 87px;
//   left: 0;
// }

// .MyAccount .logos-symbol-large {
//   position: absolute;
//   width: 73px;
//   height: 73px;
//   top: 0;
//   left: 36px;
// }

// .MyAccount .interaction-buttons {
//   display: inline-flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8px;
//   position: absolute;
//   top: 540px;
//   left: 21px;
// }

// .MyAccount .buttons {
//   all: unset;
//   box-sizing: border-box;
//   display: flex;
//   width: 333px;
//   align-items: center;
//   justify-content: center;
//   gap: 4px;
//   padding: 12px 36px;
//   position: relative;
//   flex: 0 0 auto;
//   background-color: var(--primary-colourssolar-yellow);
//   border-radius: 40px;
// }

// .MyAccount .button-label {
//   all: unset;
//   box-sizing: border-box;
//   position: relative;
//   width: fit-content;
//   margin-top: -1px;
//   font-family: "Vattenfall Hall-Medium", Helvetica;
//   font-weight: 500;
//   color: var(--primary-colourscoal-black);
//   font-size: 14px;
//   text-align: center;
//   letter-spacing: 0;
//   line-height: 16px;
//   white-space: nowrap;
// }

// .MyAccount .link {
//   display: flex;
//   width: 247px;
//   height: 26px;
//   align-items: flex-start;
//   position: absolute;
//   top: 64px;
//   left: 43px;
//   transform: rotate(-0.17deg);
// }

// .MyAccount .button {
//   all: unset;
//   box-sizing: border-box;
//   display: inline-flex;
//   align-items: center;
//   justify-content: flex-end;
//   gap: 4px;
//   position: relative;
//   flex: 0 0 auto;
//   margin-right: -16.19px;
// }

// .MyAccount .icon {
//   position: relative;
//   width: 20.06px;
//   height: 20.06px;
//   margin-left: -0.03px;
//   transform: rotate(0.17deg);
// }

// .MyAccount .label {
//   position: relative;
//   width: fit-content;
//   margin-top: -1px;
//   font-family: "Vattenfall Hall-Regular", Helvetica;
//   font-weight: 400;
//   color: var(--textprimary);
//   font-size: 16px;
//   letter-spacing: 0;
//   line-height: 24px;
//   white-space: nowrap;
// }

// .MyAccount .text-wrapper {
//   position: absolute;
//   height: 24px;
//   top: 286px;
//   left: 142px;
//   font-family: var(--MD-regular-font-family);
//   font-weight: var(--MD-regular-font-weight);
//   color: #000000;
//   font-size: var(--MD-regular-font-size);
//   letter-spacing: var(--MD-regular-letter-spacing);
//   line-height: var(--MD-regular-line-height);
//   white-space: nowrap;
//   font-style: var(--MD-regular-font-style);
// }
// .MyAccount .dropdown-title {
//   position: absolute;
//   width: 329px;
//   height: 24px;
//   top: 325px;
//   left: 35px;
//   font-family: var(--MD-regular-font-family);
//   font-weight: var(--MD-regular-font-weight);
//   font-size: var(--MD-regular-font-size);
//   color: #999999;
// }
// .MyAccount .dropdown-frame {
//   position: absolute;
//   top: 350px;
//   left: 30px;
// }
// .MyAccount .button-label-wrapper {
//   all: unset;
//   box-sizing: border-box;
//   display: flex;
//   width: 333px;
//   align-items: center;
//   justify-content: center;
//   gap: 4px;
//   padding: 12px 36px;
//   position: absolute;
//   top: 420px;
//   left: 21px;
//   background-color: var(--primary-colourssolar-yellow);
//   border-radius: 40px;
// }
// .MyAccount .text-wrapper-1 {
//   all: unset;
//   box-sizing: border-box;
//   position: relative;
//   width: fit-content;
//   margin-top: -1px;
//   font-family: "Vattenfall Hall-Medium", Helvetica;
//   font-weight: 500;
//   color: var(--primary-colourscoal-black);
//   font-size: 14px;
//   text-align: center;
//   letter-spacing: 0;
//   line-height: 16px;
//   white-space: nowrap;
// }

// .MyAccount .button-label-wrapper-2 {
//   all: unset;
//   box-sizing: border-box;
//   display: flex;
//   width: 333px;
//   align-items: center;
//   justify-content: center;
//   gap: 4px;
//   padding: 12px 36px;
//   position: absolute;
//   top: 480px;
//   left: 21px;
//   background-color: var(--primary-colourssolar-yellow);
//   border-radius: 40px;
// }
.my-ACCOUNT .info-message {
  display: flex;
  flex-direction: column;
  width: 375px;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  position: absolute;
  bottom: 50px;
  left: -25px;
  // background-color: var(--backgroundsecondaryyellow);
  background-color: #d9f5e4;
}

.my-ACCOUNT .frame-settings-input {
  width: 320px;
  gap: 8px;
  display: flex;
  align-items: center;
  position: relative;
}

.my-ACCOUNT .frame-settings-input .dropdown-2 {
  width: 180px;
  height: 45px;
  justify-content: space-between;
  padding: 13px 18px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
  display: flex;
  align-items: center;
  position: relative;
}

.my-ACCOUNT .frame-settings-input .button-2 {
  width: 133px;
  justify-content: center;
  gap: 4px;
  padding: 12px 36px;
  margin-right: -1px;
  background-color: var(--primary-colourssolar-yellow);
  border-radius: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.my-ACCOUNT .frame-settings-input .button-label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  margin-left: -1.5px;
  margin-right: -1.5px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}
