.dropdown-spotprice {
  display: flex;
  width: 320px;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
  flex-direction: column;
}
.dropdown-spotprice .skeleton {
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}
.dropdown-spotprice .select-option {
  position: absolute;
  width: 290px;
  left: 0px;
  //width: fit-content;
  // margin-top: -3.5px;
  margin-top: 0;
  // margin-bottom: -1.5px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
  // display: inline-block;
  // width: auto;
}
.dropdown-spotprice .icons-symbol-arrow {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  // margin-top: -0.5px;
  // margin-bottom: -0.5px;
  // display: inline-block;
  // width: auto;
}

.dropdown-spotprice-with {
  flex-direction: column;
  width: 315px;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  border: 2px solid;
  border-color: var(--interaction-colorsbluehover);
  display: flex;
  position: relative;
  // z-index: 99999;
  z-index: 100000;
}
.dropdown-spotprice-with .skeleton {
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}
.dropdown-spotprice-with .select-option {
  position: relative;
  //  left: 0;
  width: 290px;
  margin-top: -1px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: #767676;
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  white-space: nowrap;
  font-style: var(--regular-regular-font-style);
}
.dropdown-spotprice-with .icons-navigation-up {
  position: relative;
  //  right: 0;
  width: 20px;
  height: 20px;
}
.dropdown-spotprice-with .link {
  align-items: left;
  gap: 20px;
  left: 0px;
  // align-items: center;
  padding: 10px 14px;
  align-self: stretch;
  width: 315px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
}
.dropdown-spotprice-with .winter-spring-summer {
  position: relative;
  // width: fit-content;
  width: 310px;
  margin-top: -1px;
  margin-left: 25px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: #000000;
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  white-space: nowrap;
  font-style: var(--regular-regular-font-style);
  text-align: left;
}
.dropdown-spotprice-with .link.selected {
  display: flex;
  // width: 310px;
  width: 310px;
  left: 0px;
  // align-items: left;
  gap: 10px;
  padding: 10px 14px;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--backgroundsecondaryblue);
}
.dropdown-spotprice-with .winter-spring-summer.selected {
  position: relative;
  // width: fit-content;
  width: 310px;
  margin-top: -1px;
  margin-left: 25px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--primary-colourscoal-black);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
  text-align: left;
}
