.login-flow-reset {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.login-flow-reset .div {
  background-color: #ffffff;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 968px;
  position: relative;
}
.login-flow-reset .stacked-logos {
  position: absolute;
  width: 104px;
  height: 75px;
  top: 40px;
  left: 135px;
}
.login-flow-reset .boolean-group {
  position: absolute;
  width: 104px;
  height: 11px;
  top: 63px;
  left: 0;
}
.login-flow-reset .logos-symbol-large {
  position: absolute;
  width: 53px;
  height: 53px;
  top: 0;
  left: 26px;
}
.login-flow-reset .buttons {
  display: flex;
  width: 333px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 36px;
  position: absolute;
  top: 576px;
  left: 21px;
  background-color: var(--primary-colourssolar-yellow);
  border-radius: 40px;
}
.login-flow-reset .button-label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}
.login-flow-reset .button-label-wrapper {
  all: unset;
  box-sizing: border-box;
  display: flex;
  width: 333px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 36px;
  position: absolute;
  top: 635px;
  left: 21px;
  background-color: var(--primary-colourssolar-yellow);
  border-radius: 40px;
}
.login-flow-reset .text-wrapper {
  all: unset;
  box-sizing: border-box;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}
.login-flow-reset .overlap-group {
  position: absolute;
  width: 333px;
  height: 304px;
  top: 247px;
  left: 21px;
}
.login-flow-reset .group {
  top: 0;
  position: absolute;
  width: 333px;
  height: 104px;
  left: 0;
}
.login-flow-reset .text-input-field {
  position: relative;
  height: 104px;
}
.login-flow-reset .label {
  position: absolute;
  width: 309px;
  height: 24px;
  top: -1px;
  left: 0;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: var(--textprimary);
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
}
.login-flow-reset .frame {
  display: flex;
  width: 333px;
  align-items: center;
  gap: 10px;
  padding: 14px 20px;
  position: absolute;
  top: 31px;
  left: 0;
  background-color: var(--backgroundprimary);
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--active-line);
}
.login-flow-reset .text-input-field-wrapper {
  top: 103px;
  position: absolute;
  width: 333px;
  height: 104px;
  left: 0;
}
.login-flow-reset .label-2 {
  position: absolute;
  width: 333px;
  height: 24px;
  top: -1px;
  left: 0;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: var(--textprimary);
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
}
//    .login-flow-reset .frame-2 {
//     display: flex;
//     width: 333px;
//     height: 48px;
//     align-items: center;
//     gap: 10px;
//     padding: 14px 20px;
//     position: absolute;
//     top: 31px;
//     left: 0;
//     background-color: var(--backgroundprimary);
//     border-radius: 4px;
//     border: 1px solid;
//     border-color: var(--linedefault);
//    }
.login-flow-reset .div-wrapper {
  top: 200px;
  position: absolute;
  width: 333px;
  height: 104px;
  left: 0;
}
.login-flow-reset .text-wrapper-2 {
  position: absolute;
  height: 36px;
  top: 191px;
  left: 23px;
  font-family: var(--desktop-headers-h3-desktop-font-family);
  font-weight: var(--desktop-headers-h3-desktop-font-weight);
  color: #000000;
  font-size: var(--desktop-headers-h3-desktop-font-size);
  letter-spacing: var(--desktop-headers-h3-desktop-letter-spacing);
  line-height: var(--desktop-headers-h3-desktop-line-height);
  white-space: nowrap;
  font-style: var(--desktop-headers-h3-desktop-font-style);
}
.login-flow-reset .text-wrapper-3 {
  position: absolute;
  height: 24px;
  top: 124px;
  left: 142px;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: #000000;
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
}

.login-flow-reset .info-message {
  display: flex;
  flex-direction: column;
  width: 375px;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  position: absolute;
  top: 686px;
  left: 0;
  background-color: var(--backgroundsecondaryyellow);
}
.login-flow-reset .info-message .heading {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.login-flow-reset .info-message .frame {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}
.login-flow-reset .info-message .information {
  position: relative;
  width: 20px;
  height: 20px;
}
.login-flow-reset .info-message .element {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--LG-h2-h3-bold-font-family);
  font-weight: var(--LG-h2-h3-bold-font-weight);
  color: var(--text-primary);
  font-size: var(--LG-h2-h3-bold-font-size);
  letter-spacing: var(--LG-h2-h3-bold-letter-spacing);
  line-height: var(--LG-h2-h3-bold-line-height);
  font-style: var(--LG-h2-h3-bold-font-style);
}
.login-flow-reset .info-message .content {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.login-flow-reset .info-message .element-a {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: var(--text-primary);
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  font-style: var(--regular-regular-font-style);
}

.login-flow-reset .info-message .official-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}
.login-flow-reset .info-message .button {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 28px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--interactionblueregular);
  border-radius: 50px;
}
.login-flow-reset .info-message .spacing {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}
.login-flow-reset .info-message .label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--portal-paragraph-medium-font-family);
  font-weight: var(--portal-paragraph-medium-font-weight);
  color: var(--primary-coloursaura-white);
  font-size: var(--portal-paragraph-medium-font-size);
  text-align: center;
  letter-spacing: var(--portal-paragraph-medium-letter-spacing);
  line-height: var(--portal-paragraph-medium-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-medium-font-style);
}

.login-flow-reset .LogoutButton {
  display: flex;
  width: 333px;
  height: 39px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 0px;
  position: absolute;
  top: 700px;
  left: 21px;
  background-color: var(--primary-colourssolar-yellow);
  border-radius: 40px;
}

.login-flow-reset .text-link {
  position: relative;
  width: 265px;
  height: 28px;
  background-color: var(--primary-colourssolar-yellow);
  padding: 6px 36px;
  align-self: center;
  justify-content: center;
  display: flex;
}

.login-flow-reset .text-label {
  position: relative;
  width: 265px;
  top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}
