.frame-closed {
  display: flex;
  width: 329px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

.frame-closed .div {
  display: flex;
  width: 329px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.frame-closed .text-wrapper {
  // position: relative;
  // width: 298.81px;
  // margin-top: -1px;
  // font-family: "Vattenfall Hall-Medium", Helvetica;
  // font-weight: 500;
  // color: var(--primaryblack);
  // font-size: 22px;
  // letter-spacing: 0;
  // line-height: 28px;
  position: relative;
  width: 329px;
  margin-top: -1px;
  // font-family: var(--desktop-headers-h4-desktop-font-family);
  // font-weight: var(--desktop-headers-h4-desktop-font-weight);
  // color: var(--primaryblack);
  // font-size: var(--desktop-headers-h4-desktop-font-size);
  // letter-spacing: var(--desktop-headers-h4-desktop-letter-spacing);
  // line-height: var(--desktop-headers-h4-desktop-line-height);
  // font-style: var(--desktop-headers-h4-desktop-font-style);
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: #000000;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
  height: fit-content;
}

.frame-closed .icons-pc {
  position: relative;
  width: 20px;
  height: 20px;
}

.frame-open {
  display: flex;
  flex-direction: column;
  width: 329px;
  // height: 683px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  height: fit-content;
}

.frame-open .frame-closed {
  display: flex;
  width: 329px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

.frame-open .div-2 {
  display: flex;
  width: 329px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.frame-open .text-wrapper {
  position: relative;
  width: 329px;
  margin-top: -1px;
  // font-family: var(--desktop-headers-h4-desktop-font-family);
  // font-weight: var(--desktop-headers-h4-desktop-font-weight);
  // color: var(--primaryblack);
  // font-size: var(--desktop-headers-h4-desktop-font-size);
  // letter-spacing: var(--desktop-headers-h4-desktop-letter-spacing);
  // line-height: var(--desktop-headers-h4-desktop-line-height);
  // font-style: var(--desktop-headers-h4-desktop-font-style);
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: #000000;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
  // position: relative;
  // width: 298.81px;
  // margin-top: -1px;
  // font-family: "Vattenfall Hall-Medium", Helvetica;
  // font-weight: 500;
  // color: var(--primaryblack);
  // font-size: 22px;
  // letter-spacing: 0;
  // line-height: 28px;
}

.frame-open .icons-pc {
  position: relative;
  width: 20px;
  height: 20px;
}

.frame-open .section {
  display: flex;
  width: 329px;
  height: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

.frame-open .content {
  display: flex;
  flex-direction: column;
  width: 329px;
  height: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

.frame-open .lorem-ipsum-dolor {
  position: relative;
  width: 329px;
  height: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.frame-open .span {
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}

.frame-open .text-wrapper-2 {
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
}

.frame-open .picture {
  display: flex;
  flex-direction: column;
  width: 329px;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.frame-open .rectangle {
  position: relative;
  // width: 250px;
  // height: 700px;
  background-color: #d9d9d9;
}

.frame-open .rectangle-manual4 {
  position: relative;
  width: 202px;
  height: 371px;
  background-color: #d9d9d9;
}

.frame-open .rectangle-manual56 {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 380px;
  background-color: #d9d9d9;
}

.frame-open .rectangle-manual5 {
  position: relative;
  width: 192px;
  height: auto;
  background-color: #d9d9d9;
}

.frame-open .text-wrapper-3 {
  position: relative;
  width: 289px;
  font-family: "Vattenfall Hall-RegularItalic", Helvetica;
  font-weight: 400;
  font-style: italic;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.frame-open .p {
  position: relative;
  width: 329px;
  height: 224px;
  margin-bottom: -221.01px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.frame-open ol {
  list-style-position: inside;
}
