.navi-bar {
  display: inline-flex;
  // align-items: flex-start;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  padding: 4px 21px 16px;
  // position: relative;
  background-color: #ffffff;
  position: fixed; // Change from relative to fixed
  bottom: 0; // Add this line
  width: 100%; // Add this line to make the bar full width
}

.navi-bar .div {
  display: flex;
  flex-direction: column;
  width: 72px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: relative;
}

.navi-bar .identification-chart {
  position: relative;
  width: 20px;
  height: 20px;
}

.navi-bar .div-2 {
  display: flex;
  flex-direction: column;
  width: 72px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}

.navi-bar .text-wrapper-2 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.navi-bar .img {
  position: absolute;
  width: 20px;
  height: 17px;
  top: 2px;
  left: 0;
}

// .navi-bar .div-2.active {
//     // display: flex;
//     // flex-direction: column;
//     // width: 72px;
//     // height: 44px;
//     // align-items: left;
//     // justify-content: left;
//     // gap: 4px;
//     // position: relative;
// }

.navi-bar .active .text-wrapper-2 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  // color: var(--teritiary-coloursmedium-grey);
  color: black;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.home-content .button-usabilla {
  position: fixed;
  right: 0px;
  width: 31px;
  height: 110px;
  bottom: 100px;
  background-color: var(--primaryyellow);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-usabilla .feedback {
  position: relative;
  // top: 28px;
  // left: -20px;
  transform: rotate(-90deg);
  font-family: var(--s-medium-font-family);
  font-weight: var(--s-medium-font-weight);
  color: var(--textprimary);
  font-size: var(--s-medium-font-size);
  text-align: center;
  letter-spacing: var(--s-medium-letter-spacing);
  line-height: var(--s-medium-line-height);
  white-space: nowrap;
  font-style: var(--s-medium-font-style);
}

// .navi-bar .div-2.active .img {
//     position: absolute;
//     width: 20px;
//     height: 17px;
//     top: 2px;
//     left: 0;
// }

// :root {
//   --nav-icon-width: 40px;
// }

// section.home {
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   position: relative;
//   div.home-background {
//     height: 100vh;
//     width: 100vw;
//     overflow: hidden;
//     background-color: rgba(255, 255, 255, 0);
//     background-size: cover;
//     background-position: left;
//     -webkit-filter: blur(10px);
//     filter: blur(10px);
//     transform: scale(1.2);
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1;
//   }
//   div.home-container {
//     height: 90vh;
//     width: 70vw;
//     min-height: 100px;
//     min-width: 150px;
//     background-color: rgba(255, 255, 255, 0);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;

//     div.home-content {
//       height: 100%;
//       background-color: rgba(255, 255, 255, 0);
//       width: 100%;
//       overflow: hidden;
//       div.navi-bar {
//         min-height: 40px;
//         height: 8%;
//         width: 100%;
//         display: flex;
//         justify-content: space-around;
//         align-items: center;
//         div.left-navi {
//           width: 40vw;
//           display: flex;
//           justify-content: flex-start;
//           font-size: 2vw;
//           h1 {
//             font-family: $font-alt;
//             text-transform: uppercase;
//             font-size: 2vw;
//             font-weight: 600;
//             letter-spacing: 0.2vw;
//             z-index: 2;
//             color: #3e5b69;
//             padding-left: 0px;
//             padding-right: 1.5vw;
//           }
//           a.nav-link {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             span.nav-label {
//               font-family: $font-alt;
//               display: flex;
//               white-space: nowrap;
//               text-transform: uppercase;
//               padding: 0px 1vw;
//             }
//           }
//         }

//         div.right-navi {
//           display: flex;
//           width: 40vw;
//           justify-content: flex-end;
//           padding: 20px;
//           font-size: 2vw;
//           div.navi-user {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             text-align: center;
//             font-size: 2vw;
//             span {
//               font-family: $font-alt;
//               font-size: 2vw;
//               color: #3e5b69;
//               padding: 0 1vw;
//             }
//             button {
//               min-height: 40px;
//               width: 2vw;
//               height: 2vw;
//               min-width: 25px;
//               font-size: 2vw;
//               cursor: pointer;
//               i {
//                 font-family: "Font Awesome 5 Free";
//                 font-size: calc(20px + 0.6vw);
//                 color: #3e5b69;
//                 font-style: normal;
//                 font-weight: 900;
//               }
//             }
//           }
//           button {
//             min-height: 40px;
//             width: 2vw;
//             min-width: 25px;
//             background-color: transparent;
//             padding-right: 30px;
//             cursor: pointer;
//             i {
//               font-family: FontAwesome;
//               font-size: calc(12.5px + 0.6vw);
//               color: #3e5b69;
//               font-style: normal;
//             }
//           }
//         }
//       }
//       div.home-main {
//         overflow: hidden;
//         height: 92%;

//         div.hide {
//           display: none;
//         }

//         div.content-container {
//           margin-left: 3vw;
//           margin-right: 3vw;
//           height: 100%;

//           div.content-container-scrollable {
//             overflow: scroll;
//             scrollbar-width: none;
//             max-height: 100%;
//             flex-direction: column;

//             div.row-container {
//               display: flex;
//               flex-wrap: wrap;
//               div.col-container {
//                 display: flex;
//                 flex-wrap: wrap;
//               }
//             }
//             div.content-container-flex {
//               display: flex;
//               flex-wrap: wrap;
//             }
//           }
//           div::-webkit-scrollbar {
//             display: none; /* for Chrome, Safari, and Opera */
//           }
//         }
//       }
//     }
//   }
// }

// .nav-icon-container {
//   i {
//     font-family: FontAwesome;
//     color: #3e5b69;
//     font-style: normal;
//   }
// }
// // .nav-label {
// //             display: flex;
// //             white-space: nowrap;
// //             text-transform: capitalize;
// //           }

// @media screen and (min-width: 768px) {
//   section.home {
//     div.home-container {
//       overflow: hidden;
//       width: 90vw;
//     }
//   }
// }
