// .custom-ui {
//   text-align: center;
//   width: 500px;
//   padding: 40px;
//   background: #28bae6;
//   box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
//   color: #fff;
// }

// .custom-ui > h1 {
//   margin-top: 0;
// }

// .custom-ui > button {
//   width: 160px;
//   padding: 10px;
//   border: 1px solid #fff;
//   margin: 10px;
//   cursor: pointer;
//   background: none;
//   color: #fff;
//   font-size: 14px;
// }

.info-message {
  display: flex;
  flex-direction: column;
  width: 375px;
  align-items: flex-start;
  // margin: auto;
  gap: 16px;
  padding: 24px;
  position: absolute;
  // bottom: 80px;
  // left: -40px;
  // top: 440px;
  // left: -10px;
  background-color: var(--backgroundsecondaryyellow);
  // background-color: green;
}
.info-message .heading {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.info-message .frame-2 {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}
.info-message .information {
  position: relative;
  width: 20px;
  height: 20px;
}
.info-message .element {
  position: relative;
  flex: 1;
  // margin-top: -1px;
  text-align: left;
  margin-top: -43px;
  margin-left: -30px;
  font-family: var(--LG-h2-h3-bold-font-family);
  font-weight: var(--LG-h2-h3-bold-font-weight);
  color: var(--text-primary);
  font-size: var(--LG-h2-h3-bold-font-size);
  letter-spacing: var(--LG-h2-h3-bold-letter-spacing);
  line-height: var(--LG-h2-h3-bold-line-height);
  font-style: var(--LG-h2-h3-bold-font-style);
}
.info-message .content {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.info-message .element-a {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: var(--text-primary);
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  font-style: var(--regular-regular-font-style);
}

.info-message .official-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}
.info-message .button {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 28px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--interactionblueregular);
  border-radius: 50px;
}
.info-message .spacing {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}
.info-message .label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--portal-paragraph-medium-font-family);
  font-weight: var(--portal-paragraph-medium-font-weight);
  color: var(--primary-coloursaura-white);
  font-size: var(--portal-paragraph-medium-font-size);
  text-align: center;
  letter-spacing: var(--portal-paragraph-medium-letter-spacing);
  line-height: var(--portal-paragraph-medium-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-medium-font-style);
}
