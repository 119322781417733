.tooltip {
  display: flex;
  flex-direction: column;
  width: 130px;
  height: 55px;
  align-items: flex-start;
  gap: 4px;
  padding: 11px 12px 12px;
  position: relative;
  background-color: var(--primary-colourscoal-black);
  border-radius: 4px;
}
.tooltip .price {
  display: flex;
  flex-direction: column;
  width: 120px;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -5px;
}
.tooltip .label {
  width: 120px;
  margin-left: -1.16px;
  margin-right: -1.16px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  text-align: center;
  position: relative;
  margin-top: -1px;
  color: var(--textwhite);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
.tooltip .time {
  display: flex;
  width: 69px;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -4px;
  margin-right: -3px;
}
.tooltip .clock {
  position: relative;
  width: 16px;
  height: 16px;
}
.tooltip .text-wrapper {
  width: 49px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  position: relative;
  margin-top: -1px;
  color: var(--textwhite);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
