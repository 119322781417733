:root {
  --primary-colourscoal-black: rgba(0, 0, 0, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --backgroundprimary: rgba(255, 255, 255, 1);
  --linedefault: rgba(204, 204, 204, 1);
  --primary-colourssolar-yellow: rgba(255, 218, 0, 1);
  --interactionprimary-bluehover-focus: rgba(25, 100, 163, 1);
  --texttertiary: rgba(118, 118, 118, 1);
  --MD-regular-font-family: "VattenfallHall-Regular", Helvetica;
  --MD-regular-font-weight: 400;
  --MD-regular-font-size: 16px;
  --MD-regular-letter-spacing: 0px;
  --MD-regular-line-height: 24px;
  --MD-regular-font-style: normal;
  --desktop-headers-h3-desktop-font-family: "VattenfallHall-Bold", Helvetica;
  --desktop-headers-h3-desktop-font-weight: 700;
  --desktop-headers-h3-desktop-font-size: 28px;
  --desktop-headers-h3-desktop-letter-spacing: 0px;
  --desktop-headers-h3-desktop-line-height: 36px;
  --desktop-headers-h3-desktop-font-style: normal;

  --primary-colourscoal-black: rgba(0, 0, 0, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --primary-colourssolar-yellow: rgba(255, 218, 0, 1);
  --interactionprimary-bluehover-focus: rgba(25, 100, 163, 1);
  --texttertiary: rgba(118, 118, 118, 1);
  --primary-coloursocean-blue: rgba(32, 113, 181, 1);
  --MD-regular-font-family: "VattenfallHall-Regular", Helvetica;
  --MD-regular-font-weight: 400;
  --MD-regular-font-size: 16px;
  --MD-regular-letter-spacing: 0px;
  --MD-regular-line-height: 24px;
  --MD-regular-font-style: normal;

  --backgroundsecondaryyellow: rgba(255, 254, 229, 1);
  --text-primary: rgba(34, 34, 34, 1);
  --primary-coloursaura-white: rgba(255, 255, 255, 1);
  --interactionblueregular: rgba(32, 113, 181, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --textcontrast: rgba(255, 255, 255, 1);
  --texttertiary: rgba(118, 118, 118, 1);
  --LG-h2-h3-bold-font-family: "VattenfallHall Display-Bold", Helvetica;
  --LG-h2-h3-bold-font-weight: 700;
  --LG-h2-h3-bold-font-size: 20px;
  --LG-h2-h3-bold-letter-spacing: 0px;
  --LG-h2-h3-bold-line-height: 28px;
  --LG-h2-h3-bold-font-style: normal;
  --regular-regular-font-family: "VattenfallHall-Regular", Helvetica;
  --regular-regular-font-weight: 400;
  --regular-regular-font-size: 16px;
  --regular-regular-letter-spacing: 0px;
  --regular-regular-line-height: 24px;
  --regular-regular-font-style: normal;
  --portal-paragraph-medium-font-family: "VattenfallHall-Medium", Helvetica;
  --portal-paragraph-medium-font-weight: 500;
  --portal-paragraph-medium-font-size: 16px;
  --portal-paragraph-medium-letter-spacing: 0px;
  --portal-paragraph-medium-line-height: 24px;
  --portal-paragraph-medium-font-style: normal;
  --MD-regular-font-family: "VattenfallHall-Regular", Helvetica;
  --MD-regular-font-weight: 400;
  --MD-regular-font-size: 16px;
  --MD-regular-letter-spacing: 0px;
  --MD-regular-line-height: 24px;
  --MD-regular-font-style: normal;

  --primaryblack: rgba(0, 0, 0, 1);
  --primary-coloursocean-blue: rgba(32, 113, 181, 1);
  --teritiary-coloursmedium-dark-grey: rgba(118, 118, 118, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --primarywhite: rgba(255, 255, 255, 1);
  --vattenfall-tertiary-hydro: rgba(45, 165, 93, 1);
  --primaryyellow: rgba(255, 218, 0, 1);
  --teritiary-colourslighter-grey: rgba(230, 230, 230, 1);
  --s-medium-font-family: "VattenfallHall-Medium", Helvetica;
  --s-medium-font-weight: 500;
  --s-medium-font-size: 14px;
  --s-medium-letter-spacing: 0px;
  --s-medium-line-height: 20px;
  --s-medium-font-style: normal;

  --primaryblack: rgba(0, 0, 0, 1);
  --primary-coloursocean-blue: rgba(32, 113, 181, 1);
  --teritiary-coloursdark-grey: rgba(102, 102, 102, 1);
  --teritiary-coloursmedium-dark-grey: rgba(118, 118, 118, 1);
  --teritiary-coloursmedium-grey: rgba(153, 153, 153, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --primarywhite: rgba(255, 255, 255, 1);
  --vattenfall-tertiary-hydro: rgba(45, 165, 93, 1);
  --primaryyellow: rgba(255, 218, 0, 1);
  --teritiary-colourslighter-grey: rgba(230, 230, 230, 1);
  --s-medium-font-family: "VattenfallHall-Medium", Helvetica;
  --s-medium-font-weight: 500;
  --s-medium-font-size: 14px;
  --s-medium-letter-spacing: 0px;
  --s-medium-line-height: 20px;
  --s-medium-font-style: normal;

  --primaryblack: rgba(0, 0, 0, 1);
  --primary-colourscoal-black: rgba(0, 0, 0, 1);
  --textprimarydark: rgba(34, 34, 34, 1);
  --textwhite: rgba(255, 255, 255, 1);
  --primary-coloursmagnetic-grey: rgba(78, 72, 72, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --interactionprimary-blueregular: rgba(32, 113, 181, 1);
  --texttertiary: rgba(118, 118, 118, 1);
  --teritiary-coloursmedium-grey: rgba(153, 153, 153, 1);
  --primarywhite: rgba(255, 255, 255, 1);
  --vattenfall-tertiary-hydro: rgba(45, 165, 93, 1);
  --primaryyellow: rgba(255, 218, 0, 1);
  --backgroundsecondaryblue: rgba(237, 241, 246, 1);
  --interaction-colorsbluehover: rgba(25, 100, 163, 1);
  --textprimarycontrast: rgba(255, 255, 255, 1);
  --line-colorsdefault: rgba(204, 204, 204, 1);
  --linedefault: rgba(204, 204, 204, 1);
  --background-colourslight-blue: rgba(237, 241, 246, 1);
  --portal-paragraph-paragraph-font-family: "VattenfallHall-Regular", Helvetica;
  --portal-paragraph-paragraph-font-weight: 400;
  --portal-paragraph-paragraph-font-size: 16px;
  --portal-paragraph-paragraph-letter-spacing: 0px;
  --portal-paragraph-paragraph-line-height: 24px;
  --portal-paragraph-paragraph-font-style: normal;
  --s-medium-font-family: "VattenfallHall-Medium", Helvetica;
  --s-medium-font-weight: 500;
  --s-medium-font-size: 14px;
  --s-medium-letter-spacing: 0px;
  --s-medium-line-height: 20px;
  --s-medium-font-style: normal;
  --MD-regular-font-family: "VattenfallHall-Regular", Helvetica;
  --MD-regular-font-weight: 400;
  --MD-regular-font-size: 16px;
  --MD-regular-letter-spacing: 0px;
  --MD-regular-line-height: 24px;
  --MD-regular-font-style: normal;

  --primary-coloursocean-blue: rgba(32, 113, 181, 1);
  --secondary-coloursgreen: rgba(61, 192, 124, 1);
  --secondary-coloursred: rgba(249, 59, 24, 1);
  --asset-colours-in-useasset-dark-blue: rgba(25, 100, 163, 1);

  --primaryblack: rgba(0, 0, 0, 1);
  --teritiary-coloursmedium-dark-grey: rgba(118, 118, 118, 1);
  --desktop-headers-h4-desktop-font-family: "VattenfallHall-Bold", Helvetica;
  --desktop-headers-h4-desktop-font-weight: 700;
  --desktop-headers-h4-desktop-font-size: 22px;
  --desktop-headers-h4-desktop-letter-spacing: 0px;
  --desktop-headers-h4-desktop-line-height: 28px;
  --desktop-headers-h4-desktop-font-style: normal;
  --active-line: rgba(32, 113, 181, 1);
  --asset-colours-in-useasset-dark-yellow: rgba(241, 197, 11, 1);
  --asset-colours-in-useasset-grey: rgba(216, 216, 216, 1);
  --backgroundprimary: rgba(255, 255, 255, 1);
  --default-line: rgba(204, 204, 204, 1);
  --desktop-headers-h3-desktop-font-family: "VattenfallHall-Bold", Helvetica;
  --desktop-headers-h3-desktop-font-size: 28px;
  --desktop-headers-h3-desktop-font-style: normal;
  --desktop-headers-h3-desktop-font-weight: 700;
  --desktop-headers-h3-desktop-letter-spacing: 0px;
  --desktop-headers-h3-desktop-line-height: 36px;
  --error: rgba(249, 59, 24, 1);
  --form-help-text-font-family: "VattenfallHall-Regular", Helvetica;
  --form-help-text-font-size: 14px;
  --form-help-text-font-style: normal;
  --form-help-text-font-weight: 400;
  --form-help-text-letter-spacing: 0px;
  --form-help-text-line-height: 18px;
  --interactionprimary-bluehover-focus: rgba(25, 100, 163, 1);
  --linedefault: rgba(204, 204, 204, 1);
  --MD-regular-font-family: "VattenfallHall-Regular", Helvetica;
  --MD-regular-font-size: 16px;
  --MD-regular-font-style: normal;
  --MD-regular-font-weight: 400;
  --MD-regular-letter-spacing: 0px;
  --MD-regular-line-height: 24px;
  --portal-paragraph-medium-font-family: "VattenfallHall-Medium", Helvetica;
  --portal-paragraph-medium-font-size: 16px;
  --portal-paragraph-medium-font-style: normal;
  --portal-paragraph-medium-font-weight: 500;
  --portal-paragraph-medium-letter-spacing: 0px;
  --portal-paragraph-medium-line-height: 24px;
  --portal-paragraph-paragraph-font-family: "VattenfallHall-Regular", Helvetica;
  --portal-paragraph-paragraph-font-size: 16px;
  --portal-paragraph-paragraph-font-style: normal;
  --portal-paragraph-paragraph-font-weight: 400;
  --portal-paragraph-paragraph-letter-spacing: 0px;
  --portal-paragraph-paragraph-line-height: 24px;
  --primary-coloursaura-white: rgba(255, 255, 255, 1);
  --primary-colourscoal-black: rgba(0, 0, 0, 1);
  --primary-coloursmagnetic-grey: rgba(78, 72, 72, 1);
  --primary-coloursocean-blue: rgba(32, 113, 181, 1);
  --primary-colourssolar-yellow: rgba(255, 218, 0, 1);
  --primary-text: rgba(0, 0, 0, 1);
  --secondary-coloursdark-blue: rgba(30, 50, 79, 1);
  --SM-medium-font-family: "VattenfallHall-Medium", Helvetica;
  --SM-medium-font-size: 14px;
  --SM-medium-font-style: normal;
  --SM-medium-font-weight: 500;
  --SM-medium-letter-spacing: 0px;
  --SM-medium-line-height: 20px;
  --teritiary-colourslight-grey: rgba(204, 204, 204, 1);
  --teritiary-coloursmedium-dark-grey: rgba(118, 118, 118, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --texttertiary: rgba(118, 118, 118, 1);

  --primaryblack: rgba(0, 0, 0, 1);
  --primary-colourscoal-black: rgba(0, 0, 0, 1);
  --primary-coloursmagnetic-grey: rgba(78, 72, 72, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --teritiary-coloursmedium-grey: rgba(153, 153, 153, 1);
  --textprimarydark: rgba(34, 34, 34, 1);
  --texttertiary: rgba(118, 118, 118, 1);
  --primarywhite: rgba(255, 255, 255, 1);
  --vattenfall-tertiary-hydro: rgba(45, 165, 93, 1);
  --primary-coloursocean-blue: rgba(32, 113, 181, 1);
  --primaryyellow: rgba(255, 218, 0, 1);
  --interactionblueregular: rgba(32, 113, 181, 1);
  --linedefault: rgba(204, 204, 204, 1);
  --interactionprimary-blueregular: rgba(32, 113, 181, 1);
  --background-colourslight-blue: rgba(237, 241, 246, 1);
  --s-medium-font-family: "Vattenfall Hall", Helvetica;
  --s-medium-font-weight: 500;
  --s-medium-font-size: 14px;
  --s-medium-letter-spacing: 0px;
  --s-medium-line-height: 20px;
  --s-medium-font-style: normal;
  --backgroundsecondaryyellow: rgba(255, 254, 229, 1);
  --text-primary: rgba(34, 34, 34, 1);
  --primary-coloursaura-white: rgba(255, 255, 255, 1);
  --interactionblueregular: rgba(32, 113, 181, 1);
  --textprimary: rgba(34, 34, 34, 1);
  --textcontrast: rgba(255, 255, 255, 1);
  --texttertiary: rgba(118, 118, 118, 1);
  --LG-h2-h3-bold-font-family: "Vattenfall Hall Display", Helvetica;
  --LG-h2-h3-bold-font-weight: 700;
  --LG-h2-h3-bold-font-size: 20px;
  --LG-h2-h3-bold-letter-spacing: 0px;
  --LG-h2-h3-bold-line-height: 28px;
  --LG-h2-h3-bold-font-style: normal;
  --regular-regular-font-family: "Vattenfall Hall", Helvetica;
  --regular-regular-font-weight: 400;
  --regular-regular-font-size: 16px;
  --regular-regular-letter-spacing: 0px;
  --regular-regular-line-height: 24px;
  --regular-regular-font-style: normal;
  --portal-paragraph-medium-font-family: "Vattenfall Hall", Helvetica;
  --portal-paragraph-medium-font-weight: 500;
  --portal-paragraph-medium-font-size: 16px;
  --portal-paragraph-medium-letter-spacing: 0px;
  --portal-paragraph-medium-line-height: 24px;
  --portal-paragraph-medium-font-style: normal;
  --MD-regular-font-family: "Vattenfall Hall", Helvetica;
  --MD-regular-font-weight: 400;
  --MD-regular-font-size: 16px;
  --MD-regular-letter-spacing: 0px;
  --MD-regular-line-height: 24px;
  --MD-regular-font-style: normal;
  --mobile-text-detail-text-small-font-family: "Vattenfall Hall", Helvetica;
  --mobile-text-detail-text-small-font-weight: 400;
  --mobile-text-detail-text-small-font-size: 12px;
  --mobile-text-detail-text-small-letter-spacing: 0px;
  --mobile-text-detail-text-small-line-height: 20px;
  --mobile-text-detail-text-small-font-style: normal;
  --portal-paragraph-paragraph-font-family: "Vattenfall Hall", Helvetica;
  --portal-paragraph-paragraph-font-weight: 400;
  --portal-paragraph-paragraph-font-size: 16px;
  --portal-paragraph-paragraph-letter-spacing: 0px;
  --portal-paragraph-paragraph-line-height: 24px;
  --portal-paragraph-paragraph-font-style: normal;
}
