.toggle {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
}

.toggle .switch-off-default {
  display: flex;
  justify-content: flex-start;
  width: 60px;
  align-items: center;
  gap: 10px;
  padding: 3px;
  position: relative;
  background-color: var(--linedefault);
  border-radius: 14px;
}

.toggle .switch-off {
  position: relative;
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  border-radius: 11px;
  border: 0px none;
  border-color: var(--linedefault);
}

.toggle.active .switch-off-default {
  justify-content: flex-end;
  background-color: var(--interactionblueregular);
}

.toggle.active .switch-off {
  border-color: var(--interactionblueregular);
}

.toggle .div {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
