.actual-power-total {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.actual-power-total .div {
  background-color: #ffffff;
  overflow: hidden;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 770px;
  position: relative;
}
.actual-power-total .molecules-navigation {
  position: absolute;
  width: 375px;
  height: 55px;
  top: 0;
  left: 0;
  background-color: var(--primarywhite);
}
.actual-power-total .overlap {
  position: relative;
  height: 55px;
}
.actual-power-total .group {
  position: absolute;
  width: 332px;
  height: 10px;
  top: 24px;
  left: 22px;
}
.actual-power-total .overlap-group-wrapper {
  position: relative;
  width: 24px;
  height: 22px;
  top: 9px;
  left: 303px;
}
.actual-power-total .overlap-group {
  position: relative;
  height: 22px;
}
.actual-power-total .alarm {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
}
.actual-power-total .frame {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 14px;
  background-color: var(--vattenfall-tertiary-hydro);
  border-radius: 40px;
}
.actual-power-total .div-wrapper {
  position: absolute;
  width: 24px;
  height: 22px;
  top: 9px;
  left: 303px;
}
.actual-power-total .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 11px;
  left: 0;
}
.actual-power-total .frame-3 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}
.actual-power-total .img {
  position: relative;
  width: 20px;
  height: 20px;
}
.actual-power-total .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}
.actual-power-total .frame-wrapper {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 53px;
  position: absolute;
  top: 0px;
  left: 22px;
}
.actual-power-total .frame-4 {
  display: flex;
  flex-direction: column;
  width: 208px;
  align-items: flex-start;
  position: relative;
}
.actual-power-total .title {
  position: relative;
  width: 208px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}
.actual-power-total .overlap-2 {
  position: absolute;
  width: 374px;
  height: 305px;
  top: 210px;
  left: 0;
}
.actual-power-total .grouped-bar-line {
  position: absolute;
  width: 374px;
  height: 194px;
  // top: 105;
  left: 0;
  bottom: 50px;
}
.actual-power-total .overlap-3 {
  position: absolute;
  width: 338px;
  height: 153px;
  top: 24px;
  left: 16px;
}
.actual-power-total .frame-5 {
  display: flex;
  flex-direction: column;
  width: 337px;
  height: 153px;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
}
.actual-power-total .frame-6 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6.4px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.actual-power-total .text-wrapper-2 {
  position: relative;
  width: 28.91px;
  margin-top: -0.4px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 8px;
  text-align: right;
  letter-spacing: 0;
  line-height: 14.4px;
}
.actual-power-total .line {
  position: relative;
  flex: 1;
  flex-grow: 1;
  height: 1px;
}
.actual-power-total .vector {
  position: absolute;
  width: 303px;
  height: 105px;
  top: 26px;
  left: 34px;
}
.actual-power-total .ellipse {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 44px;
  left: 177px;
  background-color: #fdfdfd;
  border-radius: 5px;
  border: 2.56px solid;
  border-color: #000000;
}
.actual-power-total .real-time {
  display: flex;
  width: 309px;
  height: 15px;
  align-items: baseline;
  justify-content: space-between;
  position: absolute;
  top: 179px;
  left: 45px;
}
.actual-power-total .text-wrapper-3 {
  position: relative;
  width: 24px;
  margin-top: -0.4px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 8px;
  text-align: center;
  letter-spacing: 0;
  line-height: 14.4px;
}
.actual-power-total .text-wrapper-4 {
  position: absolute;
  width: 23px;
  top: 5px;
  left: 22px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 8px;
  text-align: right;
  letter-spacing: 0;
  line-height: 9.2px;
  white-space: nowrap;
}
.actual-power-total .overlap-wrapper {
  position: absolute;
  width: 90px;
  height: 62px;
  top: 3px;
  left: 146px;
}
.actual-power-total .overlap-4 {
  position: relative;
  height: 62px;
}
.actual-power-total .tooltip-wrapper {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 55px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
}
.actual-power-total .tooltip {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 55px;
  align-items: flex-start;
  gap: 4px;
  padding: 11px 12px 12px;
  position: relative;
  background-color: var(--primary-colourscoal-black);
  border-radius: 4px;
}
.actual-power-total .price {
  display: flex;
  flex-direction: column;
  width: 71px;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -5px;
}
.actual-power-total .label {
  width: 73.33px;
  margin-top: -1px;
  margin-left: -1.16px;
  margin-right: -1.16px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--textwhite);
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  position: relative;
  letter-spacing: 0;
}
.actual-power-total .time {
  display: flex;
  width: 69px;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -4px;
  margin-right: -3px;
}
.actual-power-total .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}
.actual-power-total .label-2 {
  width: 49px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--textwhite);
  font-size: 14px;
  line-height: 16px;
  position: relative;
  letter-spacing: 0;
}
.actual-power-total .polygon {
  position: absolute;
  width: 13px;
  height: 7px;
  top: 54px;
  left: 45px;
}
.actual-power-total .frame-7 {
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  top: 541px;
  left: 57px;
  right: 57px;
}
.actual-power-total .pill-tab-icon.active {
  display: inline-flex;
  height: 35px;
  gap: 10px;
  padding: 6px 16px;
  position: relative;
  flex: 0 0 auto;
  border: 2px solid;
  border-color: var(--interaction-colorsbluehover);
  align-items: flex-start;
  background-color: var(--backgroundsecondaryblue);
  border-radius: 8px;
  overflow: hidden;
}
.actual-power-total .label-3 {
  width: fit-content;
  margin-top: -2px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--textprimarydark);
  font-size: var(--portal-paragraph-paragraph-font-size);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  position: relative;
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  font-style: var(--portal-paragraph-paragraph-font-style);
}
.actual-power-total .pill-tab-icon {
  display: inline-flex;
  height: 35px;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--textprimarycontrast);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--line-colorsdefault);
}
.actual-power-total .label-4 {
  position: relative;
  width: fit-content;
  margin-top: -1.5px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--textprimarydark);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}
.actual-power-total .frame-8 {
  display: flex;
  width: 159px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 40px;
  left: 21px;
}
.actual-power-total .div-2 {
  position: relative;
  width: 32px;
  height: 32px;
}
.actual-power-total .ellipse-2 {
  position: absolute;
  width: 30px;
  height: 16px;
  top: 0;
  left: 2px;
}
.actual-power-total .ellipse-3 {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}
.actual-power-total .frame-9 {
  display: flex;
  flex-direction: column;
  width: 205px;
  height: 35px;
  align-items: flex-start;
  position: relative;
  margin-right: -86px;
}
.actual-power-total .p {
  position: relative;
  width: 205px;
  height: 17px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-coloursmagnetic-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 29.6px;
  white-space: nowrap;
}
.actual-power-total .element {
  position: relative;
  width: 119px;
  height: 18px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 23.5px;
  white-space: nowrap;
}
.actual-power-total .pill-tab-icon-3 {
  display: flex;
  flex-direction: column;
  width: 159px;
  height: 79px;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  position: absolute;
  top: 601px;
  left: 21px;
  align-items: flex-start;
  background-color: var(--background-colourslight-blue);
  border-radius: 8px;
  overflow: hidden;
}
.actual-power-total .frame-10 {
  display: flex;
  width: 147px;
  align-items: center;
  gap: 4px;
  padding: 0px 4px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -4px;
}
.actual-power-total .label-5 {
  width: 123.64px;
  margin-top: -1px;
  margin-right: -4.64px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-coloursmagnetic-grey);
  font-size: 12px;
  line-height: 24px;
  position: relative;
  letter-spacing: 0;
}
.actual-power-total .label-6 {
  width: fit-content;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--textprimarydark);
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  position: relative;
  letter-spacing: 0;
}
.actual-power-total .overlap-5 {
  position: absolute;
  width: 179px;
  height: 115px;
  top: 601px;
  left: 195px;
}
.actual-power-total .pill-tab-icon-4 {
  display: flex;
  flex-direction: column;
  width: 159px;
  height: 79px;
  justify-content: center;
  gap: 8px;
  padding: 6px 8px;
  position: absolute;
  top: 0;
  left: 0;
  align-items: flex-start;
  background-color: var(--background-colourslight-blue);
  border-radius: 8px;
  overflow: hidden;
}
.actual-power-total .frame-11 {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 4px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -7px;
}
.actual-power-total .label-7 {
  width: 121.9px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-coloursmagnetic-grey);
  font-size: 12px;
  line-height: 24px;
  position: relative;
  letter-spacing: 0;
}
.actual-power-total .button-usabilla {
  position: absolute;
  width: 31px;
  height: 80px;
  top: 35px;
  left: 148px;
  background-color: var(--primaryyellow);
  border-radius: 5px;
}
.actual-power-total .feedback {
  position: absolute;
  top: 28px;
  left: -5px;
  transform: rotate(-90deg);
  font-family: var(--s-medium-font-family);
  font-weight: var(--s-medium-font-weight);
  color: #222222;
  font-size: var(--s-medium-font-size);
  text-align: center;
  letter-spacing: var(--s-medium-letter-spacing);
  line-height: var(--s-medium-line-height);
  white-space: nowrap;
  font-style: var(--s-medium-font-style);
}
.actual-power-total .tabs-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 125px;
}
.actual-power-total .tab-wrapper.active {
  display: flex;
  width: 166px;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  // top: 218px;
  // left: 21px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-color: var(--interactionprimary-blueregular);
  background: none;
}
.actual-power-total .tab.active {
  position: relative;
  width: fit-content;
  margin-top: -4px;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: var(--interactionprimary-blueregular);
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
  background: none;
}
.actual-power-total .tab-wrapper {
  display: flex;
  width: 166px;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  // top: 218px;
  // left: 187px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #cccccc;
  background: none;
}
.actual-power-total .tab {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: var(--textprimary);
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
  background: none;
}
.actual-power-total .dropdown-frame {
  position: absolute;
  top: 75px;
  left: 195px;
}
.actual-power-total .select-option {
  position: relative;
  width: fit-content;
  margin-top: -3.5px;
  margin-bottom: -1.5px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}
.actual-power-total .icons-symbol-arrow {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -0.5px;
  margin-bottom: -0.5px;
}
.actual-power-total .frame-12 {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 15px;
  padding: 4px 0px 16px;
  position: absolute;
  top: 789px;
  left: 21px;
  background-color: #ffffff;
}
.actual-power-total .frame-13 {
  display: flex;
  flex-direction: column;
  width: 54.6px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: relative;
}
.actual-power-total .icon {
  position: absolute;
  width: 20px;
  height: 13px;
  top: 3px;
  left: 0;
}
.actual-power-total .text-wrapper-5 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}
.actual-power-total .frame-14 {
  display: flex;
  flex-direction: column;
  width: 54.6px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}
.actual-power-total .text-wrapper-6 {
  position: relative;
  width: fit-content;
  margin-left: -2.7px;
  margin-right: -2.7px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-colourscoal-black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}
.actual-power-total .icon-2 {
  height: 13px;
  top: 4px;
  position: absolute;
  width: 20px;
  left: 0;
}
.actual-power-total .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-left: -6.7px;
  margin-right: -6.7px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}
.actual-power-total .icon-3 {
  height: 17px;
  top: 2px;
  position: absolute;
  width: 20px;
  left: 0;
}
.actual-power-total .text-wrapper-8 {
  position: relative;
  width: fit-content;
  margin-left: -2.7px;
  margin-right: -2.7px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

// For Checkboxes Test

//  .container-checkbox {
//   margin-top: 15px;
//   display: flex;
//   justify-content: center;
// }

// .choices-checkbox {
//   display: grid;
//   gap: 2em;
//   grid-template-columns: 1fr 1fr 1fr;
//   width: 10em;
// }
// actual-power-per .frame-14 {
//   display: inline-flex;
//   align-items: flex-start;
//   gap: 16px;
//   position: absolute;
//   top: 297px;
//   left: 76px;
//  }
//  .actual-power-per .frame-15 {
//   display: inline-flex;
//   align-items: center;
//   gap: 8px;
//   position: relative;
//   flex: 0 0 auto;
//  }

.container-checkbox {
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  top: 0px;
  left: 76px;
  // position: relative;
}
.container-checkbox .div-3 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}
.container-checkbox .checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-color: var(--asset-colours-in-useasset-dark-blue);
}
.container-checkbox .div-2 {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}
.container-checkbox .ellipse-4 {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: var(--primary-coloursocean-blue);
  border-radius: 4px;
}
.container-checkbox .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}
.container-checkbox .ellipse-2 {
  background-color: var(--secondary-coloursgreen);
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.container-checkbox .ellipse-3 {
  background-color: var(--secondary-coloursred);
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.container-checkbox .hidden-checkbox {
  display: none;
}
