.manual {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.manual .div {
  background-color: #ffffff;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 1073px;
  position: relative;
}
.manual .overlap {
  position: absolute;
  width: 320px;
  height: 51px;
  top: 43px;
  left: 23px;
}
.manual .frame {
  display: inline-flex;
  flex-direction: column;
  height: 28px;
  align-items: center;
  gap: 48px;
  position: absolute;
  top: 0;
  left: 0;
}
.manual .frame-2 {
  display: flex;
  width: 320px;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}
.manual .text-wrapper {
  position: relative;
  width: 292px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}
.manual .close-button {
  background-color: #ffffff;
}
.manual .navigation-close {
  position: relative;
  width: 19.82px;
  height: 20px;
  background-color: #ffffff;
}
.manual .p {
  position: absolute;
  width: 279px;
  height: 24px;
  top: 27px;
  left: 0;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-dark-grey);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
}

.manual .overlap-group {
  position: absolute;
  width: 24px;
  height: 12px;
  top: 0;
  left: 0;
  background-size: 100% 100%;
}

.manual .text-wrapper-2 {
  position: absolute;
  width: 33px;
  top: 0;
  left: 0;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #030303;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
}

.manual .group {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 1px 0px;
  position: relative;
  top: 2px;
  left: 6px;
}

.manual .frame-3 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
  top: 152px;
  left: 23px;
}
.manual .frame-4 {
  display: flex;
  flex-direction: column;
  width: 329px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}
.manual .text-wrapper-3 {
  position: relative;
  width: 329px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: #000000;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 36px;
}
.manual .text-wrapper-4 {
  position: relative;
  width: 329px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-dark-grey);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
.manual .frame-5 {
  display: flex;
  flex-direction: column;
  width: 329px;
  height: 180px;
  align-items: flex-start;
  gap: 24px;
  position: relative;
}
.manual .frame-closed .frame-5 .text-wrapper {
  position: relative;
  width: 329px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-dark-grey);
  font-size: 5px;
  letter-spacing: 0;
  line-height: 24px;
}

// display: inline-flex;
// flex-direction: column;
// align-items: flex-start;
// gap: 24px;
// position: absolute;
// top: 152px;
// left: 23px;
// .manual .question {
//   display: inline-flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8px;
//   position: relative;
//   flex: 0 0 auto;
// }
// .manual .technical {
//   position: relative;
//   width: 329px;
//   height: 38px;
// }
// .manual .technical-2 {
//   display: flex;
//   width: 329px;
//   height: 38px;
//   align-items: center;
//   justify-content: center;
//   gap: 16px;
//   position: relative;
// }
// .manual .icons-pc {
//   position: relative;
//   width: 20px;
//   height: 20px;
// }
// .manual .text {
//   display: flex;
//   align-items: center;
//   position: relative;
//   flex: 1;
//   flex-grow: 1;
// }
// .manual .text-wrapper-5 {
//   position: relative;
//   width: fit-content;
//   margin-top: -1px;
//   font-family: "Vattenfall Hall-Regular", Helvetica;
//   font-weight: 400;
//   color: #000000;
//   font-size: 16px;
//   letter-spacing: 0;
//   line-height: 24px;
//   white-space: nowrap;
// }
// .manual .dividers {
//   position: relative;
//   width: 329px;
//   height: 1px;
//   background-size: cover;
//   background-position: 50% 50%;
// }
// .manual .dividers-2 {
//   position: relative;
//   width: 329px;
//   height: 1px;
//   background-size: cover;
//   background-position: 50% 50%;
// }
// .manual .dividers-3 {
//   position: relative;
//   width: 329px;
//   height: 1px;
//   background-size: cover;
//   background-position: 50% 50%;
// }
