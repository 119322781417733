form {
  display: flex;
  // flex-wrap:wrap;
  flex-direction: column;
  justify-content: center;
  // align-content: center;
  flex-grow: 1;
  padding-bottom: 1rem;
  width: 90%;
}

a,
a:visited {
  color: #fff;
}

input[type='text'],
  input[type='password'],
  //button,
  textarea {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

// label,
// button {
// 	margin-top: 1rem;
// }

// button {
// 	padding: 0.5rem;
// }

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  opacity: 1;
  background: black;
  color: white;
  padding: 0.25rem;
  position: relative;
  bottom: -80px;
  z-index: 9999;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}
.confirm-button {
  width: 25%;
  font-size: 1.25rem;
  background-color: green;
  display: flex;

  justify-content: center;
  align-items: center;
  border-radius: 50px;
  // align-content: center;
}
.confirm-button-div {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}
