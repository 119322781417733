.START {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.START .div {
  background-color: #ffffff;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 667px;
  position: relative;
}

.START .stacked-logos {
  position: absolute;
  width: 143px;
  height: 103px;
  top: 170px;
  left: 116px;
}

.START .boolean-group {
  position: absolute;
  width: 143px;
  height: 16px;
  top: 87px;
  left: 0;
}

.START .logos-symbol-large {
  position: absolute;
  width: 73px;
  height: 73px;
  top: 0;
  left: 36px;
}

.START .interaction-buttons {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 460px;
  left: 21px;
}

.START .buttons {
  all: unset;
  box-sizing: border-box;
  display: flex;
  width: 333px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 36px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primary-colourssolar-yellow);
  border-radius: 40px;
}

.START .button-label {
  all: unset;
  box-sizing: border-box;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.START .link {
  display: flex;
  width: 247px;
  height: 26px;
  align-items: flex-start;
  position: absolute;
  top: 64px;
  left: 43px;
  transform: rotate(-0.17deg);
}

.START .button {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -16.19px;
}

.START .icon {
  position: relative;
  width: 20.06px;
  height: 20.06px;
  margin-left: -0.03px;
  transform: rotate(0.17deg);
}

.START .label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--textprimary);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.START .text-wrapper {
  position: absolute;
  height: 24px;
  top: 286px;
  left: 142px;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: #000000;
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
}
.START .dropdown-frame {
  position: absolute;
  top: 330px;
  left: 30px;
}
.START .button-label-wrapper {
  all: unset;
  box-sizing: border-box;
  display: flex;
  width: 333px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 36px;
  position: absolute;
  top: 397px;
  left: 21px;
  background-color: var(--primary-colourssolar-yellow);
  border-radius: 40px;
}
.START .text-wrapper-1 {
  all: unset;
  box-sizing: border-box;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Medium", Helvetica;
  font-weight: 500;
  color: var(--primary-colourscoal-black);
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.START .info-message {
  display: flex;
  flex-direction: column;
  width: 375px;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  position: absolute;
  top: 540px;
  left: 0;
  background-color: var(--backgroundsecondaryyellow);
}
.START .info-message .heading {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.START .info-message .frame {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}
.START .info-message .information {
  position: relative;
  width: 20px;
  height: 20px;
}
.START .info-message .element {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--LG-h2-h3-bold-font-family);
  font-weight: var(--LG-h2-h3-bold-font-weight);
  color: var(--text-primary);
  font-size: var(--LG-h2-h3-bold-font-size);
  letter-spacing: var(--LG-h2-h3-bold-letter-spacing);
  line-height: var(--LG-h2-h3-bold-line-height);
  font-style: var(--LG-h2-h3-bold-font-style);
}
.START .info-message .content {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
.START .info-message .element-a {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: var(--text-primary);
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  font-style: var(--regular-regular-font-style);
}

.START .info-message .official-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}
.START .info-message .button {
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 28px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--interactionblueregular);
  border-radius: 50px;
}
.START .info-message .spacing {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}
.START .info-message .label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--portal-paragraph-medium-font-family);
  font-weight: var(--portal-paragraph-medium-font-weight);
  color: var(--primary-coloursaura-white);
  font-size: var(--portal-paragraph-medium-font-size);
  text-align: center;
  letter-spacing: var(--portal-paragraph-medium-letter-spacing);
  line-height: var(--portal-paragraph-medium-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-medium-font-style);
}
