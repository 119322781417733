* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100vh;
  width: 100vw;
}

body {
  font-family: "Dosis", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
