.dropdown {
  display: flex;
  width: 159px;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
  flex-direction: column;
}
.dropdown .skeleton {
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}
.dropdown .select-option {
  position: absolute;
  left: 0;
  width: 159px;
  margin-top: -3.5px;
  margin-bottom: -1.5px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
  // display: inline-block;
  // width: auto;
}
.dropdown .icons-symbol-arrow {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  // margin-top: -0.5px;
  // margin-bottom: -0.5px;
  // display: inline-block;
  // width: auto;
}

.dropdown-with {
  flex-direction: column;
  width: 159px;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  border: 2px solid;
  border-color: var(--interaction-colorsbluehover);
  display: flex;
  position: relative;
}
.dropdown-with .skeleton {
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}
.dropdown-with .select-option {
  position: relative;
  //  left: 0;
  width: 159px;
  margin-top: -1px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: #767676;
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  white-space: nowrap;
  font-style: var(--regular-regular-font-style);
}
.dropdown-with .icons-navigation-up {
  position: relative;
  //  right: 0;
  width: 20px;
  height: 20px;
}
.dropdown-with .link {
  align-items: left;
  gap: 10px;
  padding: 16px 14px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.dropdown-with .winter-spring-summer {
  position: relative;
  width: 155px;
  margin-top: -1px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: #000000;
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  white-space: nowrap;
  font-style: var(--regular-regular-font-style);
  text-align: left;
}
.dropdown-with .link.selected {
  display: flex;
  width: 155px;
  align-items: left;
  gap: 10px;
  padding: 16px 14px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--backgroundsecondaryblue);
}
.dropdown-with .winter-spring-summer.selected {
  position: relative;
  width: 155px;
  margin-top: -1px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--primary-colourscoal-black);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
  text-align: left;
}

// .vf-dropdown-wrapper {
//     position: relative;
//     display: inline-block;
// }

// .dropdown-label {
//     padding: 10px;
//     border: 1px solid #ccc;
//     cursor: pointer;
// }

// .dropdown-list {
//     position: absolute;
//     top: 100%;
//     left: 0;
//     width: 100%;
//     border: 1px solid #ccc;
//     background-color: #fff;
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     z-index: 1;
// }

// .dropdown-option {
//     padding: 10px;
//     cursor: pointer;
// }

// .dropdown-option:hover {
//     background-color: #f0f0f0;
// }

// .dropdown-option.selected {
//     background-color: #ddd;
// }

// *,
// *:after,
// *:before {
//   box-sizing: border-box;
// }

// .vf-dropdown {
//   height: 52px;
//   position: relative;
//   width: 256px;
// }
// .vf-dropdown-wrapper {
//   height: 52px;
//   position: relative;
//   width: 300px;
// }
// .vf-dropdown-wrapper::after {
//   background-repeat: no-repeat;
//   background-size: 36px;
//   background-position: right center;
//   background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzNiAzNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzYgMzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMzMzMzMzO30KPC9zdHlsZT4KPGcgaWQ9Ik1hc2siIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4LjAwMDAwMCwgMTguNTAwMDAwKSBzY2FsZSgxLCAtMSkgdHJhbnNsYXRlKC0xOC4wMDAwMDAsIC0xOC41MDAwMDApICI+Cgk8cG9seWdvbiBpZD0icGF0aC0xXzFfIiBjbGFzcz0ic3QwIiBwb2ludHM9IjE4LDE0LjUgMTAsMjAuMyAxMSwyMS41IDE4LDE2LjUgMjUsMjEuNSAyNiwyMC4zIAkiLz4KPC9nPgo8L3N2Zz4K);
//   bottom: 0;
//   content: "";
//   padding: 18px;
//   pointer-events: none;
//   position: absolute;
//   top: 0;
//   right: 8px;
// }
// .vf-dropdown-option {
//   cursor: pointer;
// }
// .vf-dropdown__semantic {
//   -webkit-appearance: none;
//   appearance: none;
//   border: 1px solid #e8e8e9;
//   border-radius: 4px;
//   color: #222222;
//   cursor: pointer;
//   font-family: "Vattenfall Hall NORDx";
//   font-size: 1.25rem;
//   height: 52px;
//   line-height: 24px;
//   padding: 11px 114px 11px 20px;
//   width: 300px;
// }
// .vf-dropdown__semantic:hover {
//   border: 1px solid #000;
// }
// .vf-dropdown__semantic:focus, .vf-dropdown__semantic:focus-visible {
//   border-color: #000;
// }
// .vf-dropdown__standard .vf-icon-check:before, .vf-dropdown__underline .vf-icon-check:before {
//   font-size: 1.25rem;
// }
// .vf-dropdown__standard {
//   -webkit-appearance: none;
//   appearance: none;
//   border: 1px solid #e8e8e9;
//   border-radius: 4px;
//   color: #222222;
//   cursor: pointer;
//   font-size: 1.25rem;
//   height: 52px;
//   line-height: 24px;
//   padding: 11px 114px 11px 20px;
// }
// .vf-dropdown__standard:hover {
//   border-color: #69788c;
// }
// .vf-dropdown__checkbox {
//   -webkit-appearance: none;
//   appearance: none;
//   border: 1px solid #e8e8e9;
//   border-radius: 4px;
//   color: #222222;
//   cursor: pointer;
//   font-size: 1.25rem;
//   height: 52px;
//   line-height: 24px;
//   padding: 11px 114px 11px 20px;
// }
// .vf-dropdown__checkbox:hover {
//   border-color: #69788c;
// }
// .vf-dropdown__underline {
//   background: none;
//   border: none;
//   border-bottom: 1px solid #869bad;
//   border-radius: 0px 0px 0px 0px;
//   cursor: pointer;
//   font-size: 1.25rem;
//   height: 52px;
//   padding: 11px 114px 11px 20px;
//   width: 300px;
// }
// .vf-dropdown__underline:hover {
//   border-color: #69788c;
// }
// .vf-dropdown__disabled {
//   background: #f1f1f1;
//   border: 1px solid #e8e8e9;
//   border-color: #bcbcbc;
//   border-radius: 4px;
//   color: #767676;
//   font-size: 1.25rem;
//   height: 52px;
//   line-height: 24px;
//   padding: 11px 114px 11px 20px;
// }
// .vf-dropdown-list {
//   background-color: #fff;
//   border: 1px solid #69788c;
//   border-top: none;
//   border-radius: 0 0 4px 4px;
//   display: none;
//   left: 0;
//   max-height: 500px;
//   position: absolute;
//   right: 0;
//   top: 51px;
//   z-index: 100;
// }
// .vf-dropdown-list__underline {
//   background-color: #fff;
//   border: 1px solid #69788c;
//   border-top: none;
//   border-radius: 0 0 4px 4px;
//   display: none;
//   left: 0;
//   max-height: 500px;
//   position: absolute;
//   right: 0;
//   top: 51px;
//   z-index: 100;
// }
// .vf-dropdown-list__checkbox {
//   background-color: #fff;
//   border: 1px solid #69788c;
//   border-top: none;
//   border-radius: 0 0 4px 4px;
//   display: none;
//   left: 0;
//   max-height: 500px;
//   position: absolute;
//   right: 0;
//   top: 51px;
//   z-index: 100;
// }
// .vf-dropdown-list-item {
//   right: 0;
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
//   height: 52px;
//   padding: 12px 20px;
// }
// .vf-dropdown-list-item:hover {
//   background-color: #1964a3;
//   color: #fff;
// }
// .vf-dropdown-list-item--with-checkbox {
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
//   height: 52px;
//   padding: 12px 20px;
// }
// .vf-dropdown-list-item__underline {
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
//   height: 52px;
//   padding: 12px 20px;
// }
// .vf-dropdown-list-item__underline:hover {
//   background-color: #1964a3;
//   color: #fff;
// }
// .vf-dropdown__checkbox-input {
//   -webkit-appearance: none;
//   appearance: none;
//   background: #fff;
//   border-radius: 4px 4px 4px 4px;
//   border: 1px solid #a4a9ac;
//   cursor: pointer;
//   height: 28px;
//   line-height: 1;
//   margin-right: 12px;
//   position: relative;
//   vertical-align: middle;
//   width: 28px;
// }

// .vf-dropdown-list__underline.active,
// .vf-dropdown-list__checkbox.active,
// .vf-dropdown-list.active {
//   display: block;
// }

// .vf-dropdown__standard.open,
// .vf-dropdown__checkbox.open,
// .vf-dropdown__underline.open {
//   border-top: 1px solid #69788c;
//   border-left: 1px solid #69788c;
//   border-right: 1px solid #69788c;
//   border-bottom: none;
//   border-radius: 4px 4px 0 0;
//   border-color: #69788c;
// }

// .vf-dropdown__checkbox-input::before {
//   color: #d8d8d8;
//   content: "\e97f";
//   font-family: "vf-icons";
//   font-size: 1.125rem;
//   left: 4px;
//   opacity: 0;
//   position: absolute;
//   top: 4px;
//   transform: scale(0);
//   transition: transform 0.3s ease-in-out;
// }

// .vf-dropdown__checkbox-input:hover::before {
//   opacity: 1;
//   transform: scale(1);
// }

// .vf-dropdown__checkbox-input:checked.vf-dropdown__checkbox-input::before {
//   color: #4e4b48;
//   content: "\e97f";
//   font-family: "vf-icons";
//   font-size: 1.125rem;
//   left: 4px;
//   opacity: 1;
//   position: absolute;
//   top: 4px;
//   transform: scale(1);
// }

// .vf-dropdown-list-item--with-checkbox:hover {
//   background-color: #1964a3;
//   color: #fff;
// }
// .vf-dropdown-list-item--with-checkbox label.vf-dropdown-option {
//   display: inline-block;
//   font-size: 1.25rem;
//   font-weight: normal;
//   hyphens: auto;
//   letter-spacing: 0px;
//   line-height: 22px;
//   margin-bottom: 0;
//   max-width: 210px;
//   vertical-align: middle;
//   word-break: break-all;
// }
