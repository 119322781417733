.overview {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.overview .div {
  background-color: #ffffff;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 820px;
  position: relative;
}

.overview .molecules-navigation {
  position: absolute;
  width: 375px;
  height: 118px;
  top: 0;
  left: 0;
  background-color: var(--primarywhite);
}

.overview .group {
  position: absolute;
  width: 332px;
  height: 40px;
  top: 24px;
  left: 22px;
}

.overview .overlap {
  position: relative;
  width: 327px;
  height: 22px;
  top: 9px;
}

.overview .group-2 {
  position: absolute;
  width: 327px;
  height: 22px;
  top: 0;
  left: 0;
  align-items: center;
  display: flex;
}

.overview .overlap-group {
  height: 22px;
  left: 303px;
  position: absolute;
  width: 24px;
  top: 0;
  background: none;
}

.overview .alarm {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
}

.overview .frame {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 14px;
  background-color: var(--vattenfall-tertiary-hydro);
  border-radius: 40px;
}

.overview .action-help-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 0px;
  left: 0;
}

.overview .action-help {
  position: relative;
  width: 20px;
  height: 20px;
}

.overview .icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0;
}

.overview .text-wrapper {
  position: absolute;
  top: 1px;
  left: 24px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.overview .nub {
  position: absolute;
  width: 2px;
  height: 4px;
  top: 4px;
  left: 25px;
}

.overview .text-wrapper-2 {
  position: absolute;
  width: 33px;
  top: 0;
  left: 0;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #030303;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
}

.overview .group-3 {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 1px 0px;
  position: relative;
  top: 2px;
  left: 6px;
}

.overview .carrier {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #030303;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.overview .frame-wrapper {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 53px;
  position: absolute;
  top: 0px;
  left: 22px;
}

.overview .frame-2 {
  display: flex;
  flex-direction: column;
  width: 208px;
  align-items: flex-start;
  position: relative;
}

.overview .title {
  position: relative;
  width: 208px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}

.overview .frame-3 {
  position: absolute;
  width: 333px;
  height: 224px;
  top: 40px;
  left: 21px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--teritiary-colourslighter-grey);
}

.overview .overlap-2 {
  position: absolute;
  width: 333px;
  height: 53px;
  top: 10px;
  left: 0;
}

.overview .frame-4 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 16px;
  width: 333px;
  position: absolute;
  top: 0;
  left: 0;
  background: none;
}

.overview .renewable-energy {
  position: relative;
  width: 21.14px;
  height: 21.14px;
}

.overview .title-2 {
  width: 175.12px;
  margin-top: -1.06px;
  font-size: 16.9px;
  line-height: 29.6px;
  position: relative;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  letter-spacing: 0;
  text-align: left;
}

.overview .see-details {
  position: relative;
  width: 63.96px;
  margin-top: -0.06px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-coloursocean-blue);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}

.overview .icons-pc {
  position: relative;
  width: 16.91px;
  height: 16.91px;
  margin-right: -0.14px;
}

.overview .text-wrapper-3 {
  position: absolute;
  width: 265px;
  top: 25px;
  left: 46px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursdark-grey);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 28px;
}

.overview .numbers {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 79px;
  left: 108px;
}

.overview .overlap-group-2 {
  position: relative;
  height: 117px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.overview .ellipse {
  width: 108px;
  height: 58px;
  left: 9px;
  position: relative;
  top: 0;
}

.overview .img {
  width: 117px;
  height: 117px;
  left: 0;
  position: absolute;
  top: 0;
}

.overview .element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // align-items: center;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 400;
  color: var(--primaryblack);
  font-size: 17.1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.5px;
}

.overview .span {
  font-weight: 700;
}

.overview .text-wrapper-4 {
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-size: 15.3px;
}

.overview .frame-5 {
  position: absolute;
  width: 333px;
  height: 224px;
  top: 280px;
  left: 21px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--teritiary-colourslighter-grey);
}

.overview .overlap-3 {
  position: absolute;
  width: 315px;
  height: 53px;
  top: 11px;
  left: 0px;
}

.overview .frame-6 {
  display: flex;
  width: 315px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.overview .cockpit {
  position: relative;
  width: 21px;
  height: 20px;
}

.overview .title-3 {
  width: 174.5px;
  margin-top: -1px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  letter-spacing: 0;
}

.overview .see-details-2 {
  position: relative;
  width: 68.3px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-coloursocean-blue);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}

.overview .icons-pc-2 {
  position: relative;
  width: 16.91px;
  height: 16.91px;
}

.overview .idag-exkl-moms-och {
  position: absolute;
  width: 219px;
  top: 25px;
  left: 28px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-dark-grey);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 28px;
}

.overview .text-wrapper-5 {
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #767676;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 28px;
}

.overview .text-wrapper-6 {
  font-size: 12px;
}

.overview .overlap-group-wrapper {
  left: 105px;
  position: absolute;
  width: 120px;
  height: 120px;
  top: 83px;
}

.overview .p {
  left: 26px;
  position: absolute;
  top: 39px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 400;
  color: var(--primaryblack);
  font-size: 17.1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.5px;
}

.overview .overlap-4 {
  position: absolute;
  width: 353px;
  height: 224px;
  top: 520px;
  left: 21px;
}

.overview .frame-7 {
  height: 224px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--teritiary-colourslighter-grey);
  width: 333px;
  position: absolute;
  top: 0;
  left: 0;
}

.overview .overlap-5 {
  position: absolute;
  width: 315px;
  height: 51px;
  top: 11px;
  left: 18px;
}

.overview .title-4 {
  width: 173.12px;
  margin-top: -1px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  letter-spacing: 0;
}

.overview .text-wrapper-7 {
  position: absolute;
  width: 205px;
  top: 23px;
  left: 33px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-dark-grey);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 28px;
}

.overview .div-wrapper {
  left: 108px;
  position: absolute;
  width: 120px;
  height: 120px;
  top: 83px;
}

.overview .element-2 {
  left: 30px;
  position: absolute;
  top: 39px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 400;
  color: var(--primaryblack);
  font-size: 17.1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.5px;
}

.overview .element-2 {
  left: 30px;
  position: absolute;
  top: 39px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 400;
  color: var(--primaryblack);
  font-size: 17.1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 23.5px;
}

//Check this one...
.overview .button-usabilla {
  // position: absolute;
  position: fixed;
  right: 0px;
  width: 31px;
  height: 143px;
  // top: 70px;
  // left: 322px;
  background-color: var(--primaryyellow);
  border-radius: 5px;
}

.overview .feedback {
  position: absolute;
  top: 60px;
  left: -45px;
  transform: rotate(-90deg);
  font-family: var(--s-medium-font-family);
  font-weight: var(--s-medium-font-weight);
  color: var(--textprimary);
  font-size: var(--s-medium-font-size);
  text-align: center;
  letter-spacing: var(--s-medium-letter-spacing);
  line-height: var(--s-medium-line-height);
  white-space: nowrap;
  font-style: var(--s-medium-font-style);
}

.overview .frame-8 {
  display: inline-flex;
  align-items: flex-start;
  gap: 15px;
  padding: 4px 21px 16px;
  position: absolute;
  top: 888px;
  left: 0;
  background-color: #ffffff;
}

.overview .frame-9 {
  display: flex;
  flex-direction: column;
  width: 72px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: relative;
}

.overview .identification-chart {
  position: relative;
  width: 20px;
  height: 20px;
}

.overview .icon-2 {
  height: 13px;
  top: 3px;
  position: absolute;
  width: 20px;
  left: 0;
}

.overview .text-wrapper-8 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.overview .frame-10 {
  display: flex;
  flex-direction: column;
  width: 72px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}

.overview .text-wrapper-9 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.overview .icon-3 {
  height: 17px;
  top: 2px;
  position: absolute;
  width: 20px;
  left: 0;
}
