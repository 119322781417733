.historical-view-day {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.historical-view-day .div {
  background-color: #ffffff;
  overflow: hidden;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 800px;
  position: relative;
}

.historical-view-day .molecules-navigation {
  position: absolute;
  width: 375px;
  height: 55px;
  top: 0;
  left: 0;
  background-color: var(--primarywhite);
}

.historical-view-day .overlap {
  position: relative;
  height: 55px;
}

.historical-view-day .group {
  position: absolute;
  width: 332px;
  height: 10px;
  top: 24px;
  left: 22px;
}

.historical-view-day .overlap-group-wrapper {
  position: relative;
  width: 24px;
  height: 22px;
  top: 9px;
  left: 303px;
}

.historical-view-day .overlap-group {
  position: relative;
  height: 22px;
}

.historical-view-day .alarm {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
}

.historical-view-day .frame {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 14px;
  background-color: var(--vattenfall-tertiary-hydro);
  border-radius: 40px;
}

.historical-view-day .div-wrapper {
  position: absolute;
  width: 24px;
  height: 22px;
  top: 9px;
  left: 303px;
}

.historical-view-day .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 11px;
  left: 0;
}

.historical-view-day .frame-3 {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.historical-view-day .icon-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
}

.historical-view-day .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.historical-view-day .frame-wrapper {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 53px;
  position: absolute;
  top: 0px;
  left: 22px;
}

.historical-view-day .frame-4 {
  display: flex;
  flex-direction: column;
  width: 208px;
  align-items: flex-start;
  position: relative;
}

.historical-view-day .title {
  position: relative;
  width: 208px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}

.historical-view-day .overlap-2 {
  position: absolute;
  width: 374px;
  height: 194px;
  top: 290px;
  // left: 0;
  display: flex;
  width: 374px;
}

.historical-view-day .grouped-bar-line {
  position: absolute;
  width: 374px;
  height: 194px;
  // top: 0;
  bottom: 0;
  left: 0;
}

.historical-view-day .overlap-3 {
  position: absolute;
  width: 338px;
  height: 185px;
  top: 14px;
  left: 16px;
}

.historical-view-day .frame-5 {
  display: flex;
  flex-direction: column;
  width: 337px;
  height: 153px;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  left: 0;
}

.historical-view-day .frame-6 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6.4px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.historical-view-day .text-wrapper-2 {
  position: relative;
  width: 28.91px;
  margin-top: -0.4px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 8px;
  text-align: right;
  letter-spacing: 0;
  line-height: 14.4px;
}

.historical-view-day .line {
  position: relative;
  flex: 1;
  flex-grow: 1;
  height: 1px;
}

.historical-view-day .hours-day {
  display: flex;
  width: 309px;
  height: 15px;
  align-items: baseline;
  justify-content: space-between;
  position: absolute;
  top: 165px;
  left: 29px;
}

.historical-view-day .text-wrapper-3 {
  position: relative;
  width: 24px;
  margin-top: -0.4px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 8px;
  text-align: center;
  letter-spacing: 0;
  line-height: 14.4px;
}

.historical-view-day .graph {
  position: absolute;
  width: 286px;
  height: 163px;
  top: 22px;
  left: 32px;
  overflow: hidden;
}

.historical-view-day .container {
  display: flex;
  width: 286px;
  height: 34px;
  align-items: flex-end;
  gap: 10.32px;
  padding: 0px 5.16px;
  position: relative;
  top: 99px;
  overflow: hidden;
}

.historical-view-day .lines {
  display: flex;
  height: 40.41px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  margin-top: -6.41px;
}

.historical-view-day .dark-blue {
  position: relative;
  flex: 1;
  flex-grow: 1;
  height: 40.41px;
  background-color: #ffffff;
}

.historical-view-day .dark-blue-wrapper {
  display: flex;
  height: 20.64px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-2 {
  height: 20.64px;
  background-color: #ffffff;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-2 {
  display: flex;
  height: 29.23px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-3 {
  height: 29.23px;
  background-color: #ffffff;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-3 {
  display: flex;
  height: 33.53px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-4 {
  height: 33.53px;
  background-color: #ffffff;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-4 {
  display: flex;
  align-items: flex-start;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-5 {
  height: 14.62px;
  background-color: #8fb8da;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-5 {
  display: flex;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-6 {
  height: 32px;
  background-color: #8fb8da;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-7 {
  height: 25px;
  background-color: #8fb8da;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-8 {
  height: 8.6px;
  background-color: #ffffff;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-6 {
  display: flex;
  height: 22.36px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-9 {
  height: 22.36px;
  background-color: #ffffff;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-7 {
  display: flex;
  height: 48.15px;
  align-items: flex-end;
  gap: 1.72px;
  margin-top: -14.15px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-10 {
  height: 42.13px;
  background-color: #ffffff;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-11 {
  height: 15.48px;
  background-color: #ffffff;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .container-2 {
  display: flex;
  width: 285px;
  height: 121px;
  align-items: flex-end;
  gap: 10.32px;
  padding: 0px 5.16px;
  position: absolute;
  top: 0;
  left: 33px;
}

.historical-view-day .dark-blue-12 {
  height: 109px;
  background-color: var(--primary-coloursocean-blue);
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-13 {
  height: 102.32px;
  background-color: var(--primary-coloursocean-blue);
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-8 {
  display: flex;
  height: 79.1px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-14 {
  height: 79.1px;
  background-color: var(--primary-coloursocean-blue);
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-9 {
  display: flex;
  height: 65.35px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-15 {
  height: 65.35px;
  background-color: var(--primary-coloursocean-blue);
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-10 {
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-11 {
  height: 48.15px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-16 {
  height: 106.62px;
  background-color: #2071b5;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-12 {
  display: flex;
  height: 81.68px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-17 {
  height: 81.68px;
  background-color: #2071b5;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-13 {
  display: flex;
  height: 102.32px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-18 {
  height: 102.32px;
  background-color: #2071b5;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .lines-14 {
  display: flex;
  height: 90.28px;
  align-items: flex-end;
  gap: 1.72px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-19 {
  height: 90.28px;
  background-color: #2071b5;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .dark-blue-20 {
  height: 98.88px;
  background-color: #2071b5;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.historical-view-day .text-wrapper-4 {
  position: absolute;
  width: 29px;
  top: 5px;
  left: 22px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0;
  line-height: 9.2px;
  white-space: nowrap;
}

.historical-view-day .text-wrapper-5 {
  position: absolute;
  width: 36px;
  top: 5px;
  left: 320px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0;
  line-height: 9.2px;
  white-space: nowrap;
}

.historical-view-day .vector {
  position: absolute;
  width: 280px;
  height: 97px;
  top: 30px;
  left: 50px;
}

.historical-view-day .frame-7 {
  display: flex;
  width: 159px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 40px;
  left: 21px;
}

.historical-view-day .div-2 {
  position: relative;
  width: 32px;
  height: 32px;
}

.historical-view-day .ellipse {
  position: absolute;
  width: 30px;
  height: 16px;
  top: 0;
  left: 2px;
}

.historical-view-day .img {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}

.historical-view-day .frame-8 {
  display: flex;
  flex-direction: column;
  width: 205px;
  height: 35px;
  align-items: flex-start;
  position: relative;
  margin-right: -86px;
}

.historical-view-day .title-2 {
  position: relative;
  width: 205px;
  height: 17px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-coloursmagnetic-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 29.6px;
  white-space: nowrap;
}

.historical-view-day .element {
  position: relative;
  width: 119px;
  height: 18px;
  font-family: "Vattenfall Hall Display-Bold", Helvetica;
  font-weight: 700;
  color: var(--primaryblack);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 23.5px;
  white-space: nowrap;
}

.historical-view-day .pill-tab-icon {
  display: flex;
  flex-direction: column;
  width: 159px;
  height: 79px;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  position: absolute;
  top: 639px;
  left: 21px;
  background-color: var(--background-colourslight-blue);
  border-radius: 8px;
  overflow: hidden;
}

.historical-view-day .frame-9 {
  display: flex;
  width: 147px;
  align-items: flex-start;
  gap: 4px;
  padding: 0px 4px;
  position: relative;
  flex: 0 0 auto;
  margin-top: -2.5px;
  margin-right: -4px;
}

.historical-view-day .euro-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 0px;
  position: relative;
  flex: 0 0 auto;
}

.historical-view-day .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}

.historical-view-day .flexcontainer {
  display: flex;
  flex-direction: column;
  width: 123.64px;
  align-items: flex-start;
  gap: 4px;
}

.historical-view-day .text {
  align-self: stretch;
  color: var(--primary-coloursmagnetic-grey);
  font-size: 12px;
  line-height: 16px;
  position: relative;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.historical-view-day .span {
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #4e4848;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.historical-view-day .label {
  position: relative;
  width: fit-content;
  margin-bottom: -1.5px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 400;
  color: var(--textprimarydark);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.historical-view-day .text-wrapper-6 {
  font-weight: 700;
}

.historical-view-day .text-wrapper-7 {
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-size: 14px;
}

.historical-view-day .overlap-4 {
  position: absolute;
  width: 179px;
  height: 137px;
  top: 639px;
  left: 195px;
}

.historical-view-day .pill-tab-icon-2 {
  display: flex;
  flex-direction: column;
  width: 159px;
  height: 79px;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 6px 8px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-colourslight-blue);
  border-radius: 8px;
  overflow: hidden;
}

.historical-view-day .frame-10 {
  display: flex;
  width: 150px;
  align-items: flex-start;
  gap: 4px;
  padding: 0px 4px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -7px;
}

.historical-view-day .insight-graph-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  padding: 6px 0px;
  position: relative;
  flex: 0 0 auto;
}

.historical-view-day .label-2 {
  width: 121.9px;
  margin-top: -1px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-coloursmagnetic-grey);
  font-size: 12px;
  line-height: 16px;
  position: relative;
  letter-spacing: 0;
}

.historical-view-day .label-3 {
  width: fit-content;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--textprimarydark);
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  position: relative;
  letter-spacing: 0;
}

//   .historical-view-day .button-usabilla {
//     position: absolute;
//     width: 31px;
//     height: 80px;
//     top: 57px;
//     left: 148px;
//     background-color: var(--primaryyellow);
//     border-radius: 5px;
//   }

.historical-view-day .text-wrapper-8 {
  position: absolute;
  top: 28px;
  left: -20px;
  transform: rotate(-90deg);
  font-family: var(--s-medium-font-family);
  font-weight: var(--s-medium-font-weight);
  color: #222222;
  font-size: var(--s-medium-font-size);
  text-align: center;
  letter-spacing: var(--s-medium-letter-spacing);
  line-height: var(--s-medium-line-height);
  white-space: nowrap;
  font-style: var(--s-medium-font-style);
}

//   .historical-view-day .tab {
//     display: flex;
//     width: 166px;
//     align-items: center;
//     justify-content: center;
//     padding: 16px 24px;
//     position: absolute;
//     top: 166px;
//     left: 21px;
//     border-bottom-width: 4px;
//     border-bottom-style: solid;
//     border-color: var(--interactionprimary-blueregular);
//   }

//   .historical-view-day .tab-selected {
//     position: relative;
//     width: fit-content;
//     margin-top: -4px;
//     font-family: var(--MD-regular-font-family);
//     font-weight: var(--MD-regular-font-weight);
//     color: var(--interactionprimary-blueregular);
//     font-size: var(--MD-regular-font-size);
//     letter-spacing: var(--MD-regular-letter-spacing);
//     line-height: var(--MD-regular-line-height);
//     white-space: nowrap;
//     font-style: var(--MD-regular-font-style);
//   }

//   .historical-view-day .tab-normal-wrapper {
//     display: flex;
//     width: 166px;
//     align-items: center;
//     justify-content: center;
//     padding: 16px 24px;
//     position: absolute;
//     top: 166px;
//     left: 187px;
//     border-bottom-width: 1px;
//     border-bottom-style: solid;
//     border-color: #cccccc;
//   }

//   .historical-view-day .tab-normal {
//     position: relative;
//     width: fit-content;
//     margin-top: -1px;
//     font-family: var(--MD-regular-font-family);
//     font-weight: var(--MD-regular-font-weight);
//     color: var(--textprimary);
//     font-size: var(--MD-regular-font-size);
//     letter-spacing: var(--MD-regular-letter-spacing);
//     line-height: var(--MD-regular-line-height);
//     white-space: nowrap;
//     font-style: var(--MD-regular-font-style);
//   }

.historical-view-day .frame-11 {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 15px;
  padding: 4px 0px 16px;
  position: absolute;
  top: 789px;
  left: 21px;
  background-color: #ffffff;
}

.historical-view-day .frame-12 {
  display: flex;
  flex-direction: column;
  width: 54.6px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: relative;
}

.historical-view-day .icon {
  position: absolute;
  width: 20px;
  height: 13px;
  top: 4px;
  left: 0;
}

.historical-view-day .text-wrapper-9 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.historical-view-day .frame-13 {
  display: flex;
  flex-direction: column;
  width: 54.6px;
  height: 44px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}

.historical-view-day .text-wrapper-10 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--primary-colourscoal-black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.historical-view-day .spotpris-kostnad {
  position: relative;
  width: fit-content;
  margin-left: -30.2px;
  margin-right: -30.2px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.historical-view-day .icon-2 {
  position: absolute;
  width: 20px;
  height: 17px;
  top: 2px;
  left: 0;
}

.historical-view-day .text-wrapper-11 {
  position: relative;
  width: fit-content;
  margin-left: -2.7px;
  margin-right: -2.7px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: var(--teritiary-coloursmedium-grey);
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.historical-view-day .graph-explanation {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 559px;
  left: 45px;
}

.historical-view-day .div-3 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.historical-view-day .ellipse-2 {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: var(--primary-coloursocean-blue);
  border-radius: 5px;
}

.historical-view-day .text-wrapper-12 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--mobile-text-detail-text-small-font-family);
  font-weight: var(--mobile-text-detail-text-small-font-weight);
  color: #000000;
  font-size: var(--mobile-text-detail-text-small-font-size);
  letter-spacing: var(--mobile-text-detail-text-small-letter-spacing);
  line-height: var(--mobile-text-detail-text-small-line-height);
  white-space: nowrap;
  font-style: var(--mobile-text-detail-text-small-font-style);
}

.historical-view-day .ellipse-3 {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #8fb8da;
  border-radius: 5px;
}

.historical-view-day .line-2 {
  position: relative;
  width: 14.28px;
  height: 2px;
}

.historical-view-day .frame-14 {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 200px;
  left: 22px;
}

.historical-view-day .dropdown {
  display: flex;
  width: 159px;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
}

.historical-view-day .select-option {
  position: relative;
  width: fit-content;
  margin-top: -3.5px;
  margin-bottom: -1.5px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}

.historical-view-day .icons-symbol-arrow {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: -0.5px;
  margin-bottom: -0.5px;
}

.historical-view-day .frame-15 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 257px;
  left: 21px;
}

//   .historical-view-day .toggle {
//     display: inline-flex;
//     align-items: flex-start;
//     position: relative;
//     flex: 0 0 auto;
//   }

//   .historical-view-day .switch-off-default {
//     display: flex;
//     width: 60px;
//     align-items: center;
//     justify-content: flex-end;
//     gap: 10px;
//     padding: 3px;
//     position: relative;
//     background-color: var(--interactionblueregular);
//     border-radius: 14px;
//   }

//   .historical-view-day .switch-off {
//     position: relative;
//     width: 22px;
//     height: 22px;
//     background-color: #ffffff;
//     border-radius: 11px;
//     border: 0px none;
//     border-color: var(--interactionblueregular);
//   }

.historical-view-day .text-wrapper-13 {
  position: relative;
  width: fit-content;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.historical-view-day .tabs-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 125px;
}

.historical-view-day .tab-wrapper.active {
  display: flex;
  width: 166px;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  // top: 218px;
  // left: 21px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-color: var(--interactionprimary-blueregular);
  background: none;
}

.historical-view-day .tab.active {
  position: relative;
  width: fit-content;
  margin-top: -4px;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: var(--interactionprimary-blueregular);
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
  background: none;
}

.historical-view-day .tab-wrapper {
  display: flex;
  width: 166px;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  // top: 218px;
  // left: 187px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #cccccc;
  background: none;
}

.historical-view-day .tab {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--MD-regular-font-family);
  font-weight: var(--MD-regular-font-weight);
  color: var(--textprimary);
  font-size: var(--MD-regular-font-size);
  letter-spacing: var(--MD-regular-letter-spacing);
  line-height: var(--MD-regular-line-height);
  white-space: nowrap;
  font-style: var(--MD-regular-font-style);
  background: none;
}

.historical-view-day .dropdown-spotprice {
  display: flex;
  width: 159px;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
  flex-direction: column;
}

.historical-view-day .dropdown-spotprice .skeleton {
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.historical-view-day .dropdown-spotprice .select-option {
  position: absolute;
  width: 159px;
  left: 0px;
  //width: fit-content;
  margin-top: -5px;
  // margin-top: 0;
  // margin-bottom: -1.5px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
  // display: inline-block;
  // width: auto;
}

.historical-view-day .dropdown-spotprice .icons-symbol-arrow {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  // margin-top: -0.5px;
  // margin-bottom: -0.5px;
  // display: inline-block;
  // width: auto;
}

.historical-view-day .dropdown-spotprice-with {
  flex-direction: column;
  width: 159px;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  border: 2px solid;
  border-color: var(--interaction-colorsbluehover);
  display: flex;
  position: relative;
  // z-index: 99999;
  z-index: 100000;
}

.historical-view-day .dropdown-spotprice-with .skeleton {
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.historical-view-day .dropdown-spotprice-with .select-option {
  position: relative;
  //  left: 0;
  width: 290px;
  margin-top: -1px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: #767676;
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  white-space: nowrap;
  font-style: var(--regular-regular-font-style);
}

.historical-view-day .dropdown-spotprice-with .icons-navigation-up {
  position: relative;
  //  right: 0;
  width: 20px;
  height: 20px;
}

.historical-view-day .dropdown-spotprice-with .link {
  align-items: left;
  gap: 20px;
  left: 0px;
  // align-items: center;
  padding: 10px 14px;
  align-self: stretch;
  width: 154px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
}

.historical-view-day .dropdown-spotprice-with .winter-spring-summer {
  position: relative;
  // width: fit-content;
  width: 154px;
  margin-top: -1px;
  margin-left: 25px;
  font-family: var(--regular-regular-font-family);
  font-weight: var(--regular-regular-font-weight);
  color: #000000;
  font-size: var(--regular-regular-font-size);
  letter-spacing: var(--regular-regular-letter-spacing);
  line-height: var(--regular-regular-line-height);
  white-space: nowrap;
  font-style: var(--regular-regular-font-style);
  text-align: left;
}

.historical-view-day .dropdown-spotprice-with .link.selected {
  display: flex;
  // width: 310px;
  width: 154px;
  left: 0px;
  // align-items: left;
  gap: 10px;
  padding: 10px 14px;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--backgroundsecondaryblue);
}

.historical-view-day .dropdown-spotprice-with .winter-spring-summer.selected {
  position: relative;
  // width: fit-content;
  width: 154px;
  margin-top: -1px;
  margin-left: 25px;
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--primary-colourscoal-black);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
  text-align: left;
}

.historical-view-day .custom-date-picker {
  display: flex;
  width: 159px;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 13px 18px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--linedefault);
  font-family: var(--portal-paragraph-paragraph-font-family);
  font-weight: var(--portal-paragraph-paragraph-font-weight);
  color: var(--texttertiary);
  font-size: var(--portal-paragraph-paragraph-font-size);
  letter-spacing: var(--portal-paragraph-paragraph-letter-spacing);
  line-height: var(--portal-paragraph-paragraph-line-height);
  white-space: nowrap;
  font-style: var(--portal-paragraph-paragraph-font-style);
}

.historical-view-day .calendar-icon {
  position: absolute;
  right: 17px;
  width: 20px;
  height: 20px;
  // margin-top: -0.5px;
  // margin-bottom: -0.5px;
  // display: inline-block;
  // width: auto;
}

.historical-view-day .tooltip-wrapper {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 55px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.historical-view-day .tooltip {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 55px;
  align-items: flex-start;
  gap: 4px;
  padding: 11px 12px 12px;
  position: relative;
  background-color: var(--primary-colourscoal-black);
  border-radius: 4px;
}

.historical-view-day .price {
  display: flex;
  flex-direction: column;
  width: 71px;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -5px;
}
.historical-view-day .label-tooltip {
  width: 73.33px;
  margin-top: -1px;
  margin-left: -1.16px;
  margin-right: -1.16px;
  font-family: "Vattenfall Hall-Bold", Helvetica;
  font-weight: 700;
  color: var(--textwhite);
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  position: relative;
  letter-spacing: 0;
}
.historical-view-day .time {
  display: flex;
  width: 69px;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: -4px;
  margin-right: -3px;
}

.historical-view-day .text-wrapper-tooltip {
  width: 49px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  position: relative;
  margin-top: -1px;
  color: var(--textwhite);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
