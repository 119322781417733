.topbar {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.topbar .div {
  background-color: #ffffff;
  // border: 1px solid;
  // border-color: #000000;
  width: 375px;
  height: 100px;
  position: relative;
}

.topbar .molecules-navigatio {
  position: absolute;
  width: 375px;
  height: 118px;
  top: 0;
  left: 0;
  background-color: var(--primarywhite);
}

.topbar .group {
  position: absolute;
  width: 332px;
  height: 40px;
  top: 24px;
  left: 22px;
}

.topbar .overlap {
  position: relative;
  width: 327px;
  height: 22px;
  top: 9px;
}

// .topbar .group-2 {
//     position: absolute;
//     width: 327px;
//     height: 22px;
//     top: 0;
//     left: 0;
//     align-items: center;
//     display: flex;
// }

.topbar .overlap-group {
  height: 22px;
  left: 303px;
  position: absolute;
  width: 24px;
  top: 0;
  background: none;
}

.topbar .alarm {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
}

.topbar .frame {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 14px;
  background-color: var(--vattenfall-tertiary-hydro);
  border-radius: 40px;
}

.topbar .action-help-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 0px;
  left: 0;
}

.topbar .action-help {
  position: relative;
  width: 20px;
  height: 20px;
}

.topbar .icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0;
}

.topbar .text-wrapper {
  position: absolute;
  top: 1px;
  left: 24px;
  font-family: "Vattenfall Hall-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

// .topbar .nub {
//     position: absolute;
//     width: 2px;
//     height: 4px;
//     top: 4px;
//     left: 25px;
// }

// .topbar .text-wrapper-2 {
//     position: absolute;
//     width: 33px;
//     top: 0;
//     left: 0;
//     font-family: "Vattenfall Hall-Regular", Helvetica;
//     font-weight: 400;
//     color: #030303;
//     font-size: 12px;
//     text-align: right;
//     letter-spacing: 0;
//     line-height: normal;
// }

// .topbar .group-3 {
//     display: inline-flex;
//     align-items: center;
//     gap: 5px;
//     padding: 1px 0px;
//     position: relative;
//     top: 2px;
//     left: 6px;
// }

// .topbar .carrier {
//     position: relative;
//     width: fit-content;
//     margin-top: -1px;
//     font-family: "Vattenfall Hall-Regular", Helvetica;
//     font-weight: 400;
//     color: #030303;
//     font-size: 12px;
//     letter-spacing: 0;
//     line-height: normal;
// }

.topbar .frame-wrapper {
  display: flex;
  width: 333px;
  align-items: flex-start;
  gap: 53px;
  position: absolute;
  top: 72px;
  left: 22px;
}

.topbar .frame-2 {
  display: flex;
  flex-direction: column;
  width: 208px;
  align-items: flex-start;
  position: relative;
}
